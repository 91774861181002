import React from "react";
import Text from "../../../../components/Text";
import PropType from "prop-types";
import Heading from "../../../../components/Heading";
import FormulaValue from "../../../../components/Discovery/FormulaValue";
import EstimationScenarioTypes from "../../../../utils/estimation-scenario-types";
import { useMobileMediaQuery } from "../../../../components/Responsive";
import ToText from "../Statistics/ToText";

const CurrencyRange = ({ children, ...props }) => {
  const isMobile = useMobileMediaQuery();
  return isMobile ? (
    <Text alignment={"right"} variant={"bodyMobile"} {...props}>
      {children}
    </Text>
  ) : (
    <Heading level={"h4"} align={"right"} {...props}>
      {children}
    </Heading>
  );
};

const ImpactValue = ({
  discoveryKPI,
  lowImpactValue,
  potentialImpactValue,
  currency,
  reportId
}) => {
  const isMobile = useMobileMediaQuery();

  const textVariant = isMobile ? "bodyMobile" : "h4";
  return lowImpactValue !== potentialImpactValue ? (
    <CurrencyRange variant={textVariant} data-cy={"impact-value"}>
      <FormulaValue
        estimationScenario={EstimationScenarioTypes.LOW}
        discoveryKPI={discoveryKPI}
        currency={currency}
        reportId={reportId}
        disableFormulaPanel={true}
        showTooltip={false}
        cursor={"pointer"}
      >
        {lowImpactValue}
      </FormulaValue>
      <Text variant={textVariant}>
        <ToText />
      </Text>
      <FormulaValue
        estimationScenario={EstimationScenarioTypes.POTENTIAL}
        discoveryKPI={discoveryKPI}
        currency={currency}
        reportId={reportId}
        disableFormulaPanel={true}
        showTooltip={false}
        cursor={"pointer"}
      >
        {potentialImpactValue}
      </FormulaValue>
    </CurrencyRange>
  ) : (
    <CurrencyRange
      variant={isMobile ? "bodyMobile" : "h4"}
      data-cy={"impact-value"}
    >
      <FormulaValue
        estimationScenario={EstimationScenarioTypes.LOW}
        discoveryKPI={discoveryKPI}
        currency={currency}
        reportId={reportId}
        disableFormulaPanel={true}
        showTooltip={false}
        cursor={"pointer"}
      >
        {lowImpactValue}
      </FormulaValue>
    </CurrencyRange>
  );
};

ImpactValue.propTypes = {
  lowImpactValue: PropType.number.isRequired,
  potentialImpactValue: PropType.number.isRequired,
  currency: PropType.string.isRequired,
  discoveryKPI: PropType.object.isRequired,
  reportId: PropType.string
};

export default ImpactValue;
