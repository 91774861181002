import { withRouter } from "react-router-dom";
import React, { useEffect } from "react";
import {
  selectRequestData,
  selectRequestState,
  selectAllDiscoveryTypes,
  selectMasterDataVersion
} from "../../../store/reducers";
import { selectLatestVersionNumber } from "../../../store/selectors/versions";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import actionTypes from "../../../store/actionTypes";
import { downloadStrings, getStrings } from "../../../store/actions/strings";
import Form from "../../../components/Form";
import { updateBrandingStrings } from "../../../store/actions/configurations";
import { getLatestVersion } from "../../../store/actions/versions";
import { getMasterData } from "../../../store/actions/master-data";
import useLoadingState from "../../../utils/use-loading-state";
import { Space } from "antd";
import { useString as s } from "../../../components/StringProvider";
import styled from "styled-components";
import UpdateBrandingStringsForm from "./UpdateBrandingStringsForm";
import NewButton from "../../../components/NewButton";
import openDownload from "../../../utils/open-download";
import Notification from "../../../components/Notification";

const Strings = ({
  strings,
  updateBrandingStrings,
  updateBrandingStringsLoadingState,
  downloadStrings,
  downloadStringsLoadingState,
  downloadStringsFile,
  getStrings,
  discoveryTypes,
  getMasterData,
  getLatestVersion,
  latestVersionNumber,
  masterDataVersion
}) => {
  const [formStrings] = Form.useForm();

  const updateBrandingStringsSuccess = s(
    "organization.page.updateBranding.popup.messages.stringsSuccess",
    "The Strings have been updated successfully"
  );
  const updateBrandingStringsFailure = s(
    "organization.page.updateBranding.popup.messages.stringsFailure",
    "The Strings update failed"
  );
  const downloadStringsButtonText = s(
    "organization.page.updateBranding.downloadStrings.text",
    "Download JS Strings"
  );
  const downloadError = s(
    "organization.page.updateBranding.downloadError",
    "Error exporting file"
  );
  const stringsSaveText = s(
    "organization.page.updateBranding.strings.save",
    "Save Strings"
  );

  const onSubmitStrings = (data) => {
    updateBrandingStrings(data);
  };

  useEffect(() => {
    getStrings();
    getLatestVersion();
  }, []);

  useEffect(() => {
    if (latestVersionNumber && latestVersionNumber !== masterDataVersion) {
      getMasterData({ version: latestVersionNumber });
    }
  }, [latestVersionNumber, masterDataVersion]);

  useLoadingState(
    updateBrandingStringsLoadingState,
    () => {
      Notification.success(updateBrandingStringsSuccess);
      getStrings();
    },
    () => {
      Notification.error(updateBrandingStringsFailure);
    }
  );

  useLoadingState(
    downloadStringsLoadingState,
    () => {
      openDownload(downloadStringsFile.url);
    },
    () => {
      Notification.error(downloadError);
    }
  );

  if (!strings) {
    return null;
  }

  return (
    <Container>
      <UpdateBrandingHeader>
        <NewButton onClick={() => downloadStrings()}>
          {downloadStringsButtonText}
        </NewButton>
      </UpdateBrandingHeader>
      <StringsContainer>
        <Space direction={"vertical"} size={16}>
          <UpdateBrandingStringsForm
            form={formStrings}
            initialValues={strings}
            onSubmitStrings={onSubmitStrings}
            submitButtonText={stringsSaveText}
            discoveryTypes={discoveryTypes}
          />
        </Space>
      </StringsContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

const StringsContainer = styled.div``;

const UpdateBrandingHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getStrings,
      updateBrandingStrings,
      downloadStrings,
      getMasterData,
      getLatestVersion
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  strings: selectRequestData(state, actionTypes.GET_STRINGS_REQUEST) || {},
  updateBrandingStringsLoadingState: selectRequestState(
    state,
    actionTypes.UPDATE_BRANDING_STRINGS_REQUEST
  ),
  downloadStringsLoadingState: selectRequestState(
    state,
    actionTypes.DOWNLOAD_STRINGS_REQUEST
  ),
  downloadStringsFile: selectRequestData(
    state,
    actionTypes.DOWNLOAD_STRINGS_REQUEST
  ),
  discoveryTypes: selectAllDiscoveryTypes(state),
  masterDataVersion: selectMasterDataVersion(state),
  latestVersionNumber: selectLatestVersionNumber(state)
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Strings);
