const getStringsFormConfig = ({ s, discoveryTypeCode }) => {
  let discoveryTypePrefix = `discovery_types.${discoveryTypeCode}.`;
  if (!discoveryTypeCode) {
    discoveryTypePrefix = "";
  }
  return {
    generalStrings: [
      {
        groupId: 1,
        groupName: s(
          "updateBranding.page.generalStringsForm.group1.name",
          "Variables"
        ),
        values: [
          {
            name: "variables.companyName",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.companyName",
              "Company Name"
            )
          },
          {
            name: "variables.accountFieldName",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.accountName",
              "Account Name"
            )
          },
          {
            name: "variables.opportunityFieldName",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.opportunityName",
              "Opportunity"
            )
          }
        ]
      },
      {
        groupId: 2,
        groupName: s(
          "updateBranding.page.generalStringsForm.group2.name",
          "New Discovery Modal"
        ),
        values: [
          {
            name: "new_discovery.popup.discovery_type.header",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.discoveryTypeLabel",
              "Discovery Type Field, Label"
            )
          },
          {
            name: "new_discovery.popup.discovery_type.placeholder",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.discoveryTypePlaceholder",
              "Discovery Type Field, Placeholder Text"
            )
          },
          {
            name: "new_discovery.popup.box1.header",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.accountNameLabel",
              "Account Name Field, Label"
            )
          },
          {
            name: "new_discovery.popup.box1.text",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.accountNamePlaceholder",
              "Account Name Field, Placeholder Text"
            )
          },
          {
            name: "new_discovery.popup.name.validation",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.accountNameValidation",
              "Account Name Field, Validation Text"
            )
          },
          {
            name: "new_discovery.popup.box2.header",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.opportunityLabel",
              "Opportunity Field, Label"
            )
          },
          {
            name: "new_discovery.popup.box2.text",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.opportunityPlaceholder",
              "Opportunity Field, Placeholder Text"
            )
          },
          {
            name: "new_discovery.popup.opportunity.validation",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.opportunityValidation",
              "Opportunity Field, Validation Text"
            )
          },
          {
            name: "new_discovery.popup.box3.header",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.descriptionLabel",
              "Description Field, Label"
            )
          },
          {
            name: "new_discovery.popup.box3.text",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.descriptionPlaceholder",
              "Description Field, Placeholder Text"
            )
          },
          {
            name: "new_discovery.popup.version.validation",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.descriptionValidation",
              "Description Field, Validation Text"
            )
          }
        ]
      },
      {
        groupId: 3,
        groupName: s(
          "updateBranding.page.generalStringsForm.group3.name",
          "New Discovery Page"
        ),
        values: [
          {
            name: "start_discovery.page.header",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.startDiscoveryPageHeader",
              "Start Discovery Page, Header"
            )
          },
          {
            name: "start_discovery.page.subheader",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.startDiscoveryPageSubHeader",
              "Start Discovery Page, Sub Header"
            )
          }
        ]
      },
      {
        groupId: 4,
        groupName: s(
          "updateBranding.page.generalStringsForm.group4.name",
          "ROI Edit Assumptions"
        ),
        values: [
          {
            name: "discovery.roi.form.input1",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.roiEditAssumptionsInput1",
              "ROI Edit Assumptions, Annual Investment, Tooltip"
            )
          },
          {
            name: "discovery.roi.form.input2",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.roiEditAssumptionsInput2",
              "ROI Edit Assumptions, Upfront Investment, Tooltip"
            )
          }
        ]
      },
      {
        groupId: 5,
        groupName: s(
          "updateBranding.page.generalStringsForm.group5.name",
          "Register Interest Form"
        ),
        optional: true,
        values: [
          {
            name: "register_interest.popup.privacy.link1.label",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestPrivacyLinkText",
              "Lead Generation, Register Interest Modal, Privacy Link Text"
            )
          },
          {
            name: "register_interest.popup.privacy.link1.url",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestPrivacyLinkURL",
              "Lead Generation, Register Interest Modal, Privacy Link URL"
            )
          },
          {
            name: "register_interest.popup.contact.label",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestContactLabel",
              "Lead Generation, Register Interest Modal, Contact Label"
            )
          },
          {
            name: "register_interest.popup.header",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestHeader",
              "Lead Generation, Register Interest Modal, Header"
            )
          },
          {
            name: "register_interest.popup.smallText",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestSmallText",
              "Lead Generation, Register Interest Modal, Disclaimer"
            )
          },
          {
            name: "register_interest.popup.countryMandatory.label",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestCountryMandatoryLabel",
              "Lead Generation, Register Interest Modal, Country Mandatory Label"
            )
          },
          {
            name: "register_interest.popup.countryOptional.label",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestCountryOptionalLabel",
              "Lead Generation, Register Interest Modal, Country Optional Label"
            )
          },
          {
            name: "register_interest.popup.country.validation",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestCountryValidation",
              "Lead Generation, Register Interest Modal, Country Validation"
            )
          },
          {
            name: "register_interest.popup.customerName.label",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestCustomerNameLabel",
              "Lead Generation, Register Interest Modal, Customer Name Label"
            )
          },
          {
            name: "register_interest.popup.customerName.validation",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestCustomerNameValidation",
              "Lead Generation, Register Interest Modal, Customer Name Validation"
            )
          },
          {
            name: "register_interest.popup.email.label",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestEmailLabel",
              "Lead Generation, Register Interest Modal, Email Label"
            )
          },
          {
            name: "register_interest.popup.email.validation",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestEmailValidation",
              "Lead Generation, Register Interest Modal, Email Validation"
            )
          },
          {
            name: "register_interest.popup.firstName.label",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestFirstNameLabel",
              "Lead Generation, Register Interest Modal, First Name Label"
            )
          },
          {
            name: "register_interest.popup.firstName.validation",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestFirstNameValidation",
              "Lead Generation, Register Interest Modal, First Name Validation"
            )
          },
          {
            name: "register_interest.popup.lastName.label",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestLastNameLabel",
              "Lead Generation, Register Interest Modal, Last Name Label"
            )
          },
          {
            name: "register_interest.popup.lastName.validation",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestLastNameValidation",
              "Lead Generation, Register Interest Modal, Last Name Validation"
            )
          },
          {
            name: "register_interest.popup.phone.label",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestPhoneLabel",
              "Lead Generation, Register Interest Modal, Phone Label"
            )
          },
          {
            name: "register_interest.popup.phone.validation",
            label: s(
              "updateBranding.page.generalStringsForm.values.label.registerInterestPhoneValidation",
              "Lead Generation, Register Interest Modal, Phone Validation"
            )
          }
        ]
      },
      {
        groupId: 6,
        groupName: s(
          "updateBranding.page.generalStringsForm.group6.name",
          "Email Templates"
        ),
        values: [
          {
            name: "share.popup.template.challenges.subject",
            label: s(
              "updateBranding.page.generalStringsForm.values.challengesEmailTemplate.subject.label",
              "Challenges Email Template - Subject"
            )
          },
          {
            name: "share.popup.template.challenges.body",
            label: s(
              "updateBranding.page.generalStringsForm.values.challengesEmailTemplate.body.label",
              "Challenges Email Template - Body"
            )
          },
          {
            name: "share.popup.template.kpis.subject",
            label: s(
              "updateBranding.page.generalStringsForm.values.kpisEmailTemplate.subject.label",
              "KPIs Email Template - Subject"
            )
          },
          {
            name: "share.popup.template.kpis.body",
            label: s(
              "updateBranding.page.generalStringsForm.values.kpisEmailTemplate.body.label",
              "KPIs Email Template - Body"
            )
          },
          {
            name: "share.popup.template.valueHypothesis.subject",
            label: s(
              "updateBranding.page.generalStringsForm.values.valueHypothesisEmailTemplate.subject.label",
              "Value Hypothesis Email Template - Subject"
            )
          },
          {
            name: "share.popup.template.valueHypothesis.body",
            label: s(
              "updateBranding.page.generalStringsForm.values.valueHypothesisEmailTemplate.body.label",
              "Value Hypothesis Email Template - Body"
            )
          },
          {
            name: "share.popup.template.roi.subject",
            label: s(
              "updateBranding.page.generalStringsForm.values.roiEmailTemplate.subject.label",
              "ROI Email Template - Subject"
            )
          },
          {
            name: "share.popup.template.roi.body",
            label: s(
              "updateBranding.page.generalStringsForm.values.roiEmailTemplate.body.label",
              "ROI Email Template - Body"
            )
          }
        ]
      },
      {
        groupId: 7,
        groupName: s(
          "updateBranding.page.generalStringsForm.group7.name",
          "Web Report"
        ),
        values: [
          {
            name: "report.page.subheader2",
            label: s(
              "updateBranding.page.generalStringsForm.values.webReportSubHeader.label",
              'Web Report (Challenges, KPIs, Value Hypothesis) Intro Sub Header ("Sell Outcomes...")'
            )
          },
          {
            name: "report.end.header",
            label: s(
              "updateBranding.page.generalStringsForm.values.webReportEndMessage.label",
              "Web Report, End Message"
            )
          },
          {
            name: "report.your_situation.header",
            label: s(
              "updateBranding.page.generalStringsForm.values.reportYourSituationHeader.label",
              "Report, Your Situation Header"
            )
          },
          {
            name: "report.kpi.header",
            label: s(
              "updateBranding.page.generalStringsForm.values.reportKPIHeader.label",
              "Report, KPI Header"
            )
          }
        ]
      }
    ],
    discoveryTypeStrings: [
      {
        groupId: 1,
        groupName: s(
          "updateBranding.page.discoveryTypeStringsForm.group1.name",
          "Variables"
        ),
        values: [
          {
            name: `${discoveryTypePrefix}variables.challenges`,
            label: s(
              "updateBranding.page.generalStringsForm.values.label.challenges",
              "Challenges"
            )
          },
          {
            name: `${discoveryTypePrefix}variables.challenge`,
            label: s(
              "updateBranding.page.generalStringsForm.values.label.challenge",
              "Challenge"
            )
          },
          {
            name: `${discoveryTypePrefix}variables.outcomes`,
            label: s(
              "updateBranding.page.generalStringsForm.values.label.outcomes",
              "Outcomes"
            )
          },
          {
            name: `${discoveryTypePrefix}variables.outcome`,
            label: s(
              "updateBranding.page.generalStringsForm.values.label.outcome",
              "Outcome"
            )
          },
          {
            name: `${discoveryTypePrefix}variables.inputs`,
            label: s(
              "updateBranding.page.generalStringsForm.values.label.inputs",
              "Inputs"
            )
          },
          {
            name: `${discoveryTypePrefix}variables.input`,
            label: s(
              "updateBranding.page.generalStringsForm.values.label.input",
              "Input"
            )
          },
          {
            name: `${discoveryTypePrefix}variables.segmentation`,
            label: s(
              "updateBranding.page.generalStringsForm.values.label.segmentation",
              "Segmentation"
            )
          },
          {
            name: `${discoveryTypePrefix}variables.valueHypothesis`,
            label: s(
              "updateBranding.page.generalStringsForm.values.label.valueHypothesis",
              "Value Hypothesis"
            )
          },
          {
            name: `${discoveryTypePrefix}variables.returnOnInvestment`,
            label: s(
              "updateBranding.page.generalStringsForm.values.label.returnOnInvestment",
              "Return on Investment"
            )
          },
          {
            name: `${discoveryTypePrefix}variables.roi`,
            label: s(
              "updateBranding.page.generalStringsForm.values.label.roi",
              "ROI"
            )
          }
        ]
      },
      {
        groupId: 2,
        groupName: s(
          "updateBranding.page.discoveryTypeStringsForm.group2.name",
          "Segmentation Page"
        ),
        values: [
          {
            name: `${discoveryTypePrefix}phases.questions`,
            label: s(
              "updateBranding.page.generalStringsForm.values.segmentationPage.navigationTitle",
              "Navigation Title"
            )
          }
        ]
      },
      {
        groupId: 3,
        groupName: s(
          "updateBranding.page.discoveryTypeStringsForm.group3.name",
          "Challenges Page"
        ),
        values: [
          {
            name: `${discoveryTypePrefix}phases.challengesSolved`,
            label: s(
              "updateBranding.page.generalStringsForm.values.challengesPage.navigationTitle",
              "Navigation Title"
            )
          },
          {
            name: `${discoveryTypePrefix}discovery.challenges.page.header`,
            label: s(
              "updateBranding.page.generalStringsForm.values.challengesPage.pageHeading",
              "Page Header"
            )
          },
          {
            name: `${discoveryTypePrefix}discovery.challenges.page.subheader`,
            label: s(
              "updateBranding.page.generalStringsForm.values.challengesPage.pageSubHeader",
              "Page Sub-Header"
            )
          }
        ]
      },
      {
        groupId: 4,
        groupName: s(
          "updateBranding.page.discoveryTypeStringsForm.group4.name",
          "Outcomes Page"
        ),
        values: [
          {
            name: `${discoveryTypePrefix}phases.kpisImpacted`,
            label: s(
              "updateBranding.page.generalStringsForm.values.outcomesPage.navigationTitle",
              "Navigation Title"
            )
          },
          {
            name: `${discoveryTypePrefix}discovery.kpi.page.header`,
            label: s(
              "updateBranding.page.generalStringsForm.values.outcomesPage.pageHeading",
              "Page Header"
            )
          },
          {
            name: `${discoveryTypePrefix}discovery.kpi.page.subheader`,
            label: s(
              "updateBranding.page.generalStringsForm.values.outcomesPage.pageSubHeader",
              "Page Sub-Header"
            )
          }
        ]
      },
      {
        groupId: 5,
        groupName: s(
          "updateBranding.page.discoveryTypeStringsForm.group5.name",
          "Inputs Page"
        ),
        values: [
          {
            name: `${discoveryTypePrefix}phases.metrics`,
            label: s(
              "updateBranding.page.generalStringsForm.values.inputsPage.navigationTitle",
              "Navigation Title"
            )
          },
          {
            name: `${discoveryTypePrefix}discovery.metrics.page.header`,
            label: s(
              "updateBranding.page.generalStringsForm.values.inputsPage.pageHeading",
              "Page Header"
            )
          },
          {
            name: `${discoveryTypePrefix}discovery.metrics.page.subheader`,
            label: s(
              "updateBranding.page.generalStringsForm.values.inputsPage.pageSubHeader",
              "Page Sub-Header"
            )
          }
        ]
      },
      {
        groupId: 6,
        groupName: s(
          "updateBranding.page.discoveryTypeStringsForm.group6.name",
          "Value Hypothesis Page"
        ),
        values: [
          {
            name: `${discoveryTypePrefix}phases.valueHypothesis`,
            label: s(
              "updateBranding.page.generalStringsForm.values.valueHypothesisPage.navigationTitle",
              "Navigation Title"
            )
          },
          {
            name: `${discoveryTypePrefix}discovery.opportunity.page.header`,
            label: s(
              "updateBranding.page.generalStringsForm.values.valueHypothesisPage.pageHeading",
              "Page Header"
            )
          },
          {
            name: `${discoveryTypePrefix}discovery.opportunity.page.subheader`,
            label: s(
              "updateBranding.page.generalStringsForm.values.valueHypothesisPage.pageSubHeader",
              "Page Sub-Header"
            )
          }
        ]
      },
      {
        groupId: 7,
        groupName: s(
          "updateBranding.page.discoveryTypeStringsForm.group7.name",
          "ROI Page"
        ),
        values: [
          {
            name: `${discoveryTypePrefix}phases.returnOnInvestment`,
            label: s(
              "updateBranding.page.generalStringsForm.values.roiPage.navigationTitle",
              "Navigation Title"
            )
          },
          {
            name: `${discoveryTypePrefix}discovery.roi.page.header`,
            label: s(
              "updateBranding.page.generalStringsForm.values.roiPage.editAssumptionsPageHeading",
              "Edit Assumptions Page Header"
            )
          },
          {
            name: `${discoveryTypePrefix}discovery.roi.page.subheader`,
            label: s(
              "updateBranding.page.generalStringsForm.values.roiPage.editAssumptionsPageSubHeader",
              "Edit Assumptions Page Sub-Header"
            )
          },
          {
            name: `${discoveryTypePrefix}discovery.roi.page.header2`,
            label: s(
              "updateBranding.page.generalStringsForm.values.roiPage.returnOnInvestmentPageHeader",
              "Return on Investment Page Header"
            )
          },
          {
            name: `${discoveryTypePrefix}discovery.roi.page.subheader2`,
            label: s(
              "updateBranding.page.generalStringsForm.values.roiPage.returnOnInvestmentPageSubHeader",
              "Return on Investment Page Sub-Header"
            )
          }
        ]
      }
    ]
  };
};

export default getStringsFormConfig;
