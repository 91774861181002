import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import Group from "../../../components/Discovery/Group";
import {
  hasPermission,
  selectDiscoveryCurrency,
  selectDiscoveryGroup,
  selectDiscoveryId,
  selectDiscoveryMetricCodeStringByGroup
} from "../../../store/reducers";
import MetricWidget from "./MetricWidget";
import SubtotalTable from "./SubtotalTable";
import Permissions from "../../../utils/permissions";
import GroupVisualizations from "../../../utils/group-visualizations";

const MetricGroup = ({
  groupCode,
  metricCodeString,
  discoveryId,
  currency,
  discoveryGroup,
  initiallyCollapsed
}) => {
  const renderGroup = () => {
    switch (discoveryGroup.definition.visualizationOption) {
      case GroupVisualizations.SUBTOTAL_GROUP:
        return (
          <SubtotalTable
            discoveryId={discoveryId}
            currency={currency}
            metricCodeString={metricCodeString}
            discoveryGroup={discoveryGroup}
          />
        );
      default:
        return metricCodeString
          .split(",")
          .map((metricCode, index) => (
            <MetricWidget
              key={index}
              discoveryId={discoveryId}
              metricCode={metricCode}
              currency={currency}
            />
          ));
    }
  };
  return (
    <Group groupCode={groupCode} initiallyCollapsed={initiallyCollapsed}>
      {renderGroup()}
    </Group>
  );
};
MetricGroup.propTypes = {
  groupCode: PropTypes.string.isRequired,
  metricCodeString: PropTypes.string.isRequired,
  discoveryId: PropTypes.string.isRequired,
  currency: PropTypes.string,
  initiallyCollapsed: PropTypes.bool
};
MetricGroup.defaultProps = {
  currency: "GBP"
};

const mapStateToProps = (state, props) => ({
  discoveryId: selectDiscoveryId(state),
  currency: selectDiscoveryCurrency(state),
  metricCodeString: selectDiscoveryMetricCodeStringByGroup(
    state,
    props.groupCode,
    hasPermission(state, Permissions.SEE_ALL_METRICS)
  ),
  discoveryGroup: selectDiscoveryGroup(state, props.groupCode)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MetricGroup
);
