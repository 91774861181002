import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Heading from "../../../components/Heading";
import { useString as s } from "../../../components/StringProvider";
import { PageSection } from "../../../components/Page";
import MobileReportSection from "./MobileReportSection";
import NewButton from "../../../components/NewButton";
import MobileReportTitle from "./MobileReportTitle";
import KPISummaryCards from "../../KPISummary/KPISummaryCards";
import {
  selectDiscoveryName,
  selectDiscoverySelectedKPIs
} from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import { useMobileMediaQuery } from "../../../components/Responsive";

const ReportKPI = ({ discoveryName, discoveryKPIs, reportId }) => {
  const isMobile = useMobileMediaQuery();
  const header = s("report.kpi.header", "{outcomes} for {discoveryName}", {
    discoveryName
  });
  const subheader = s(
    "report.kpis.subheader",
    "By solving the identified business challenges together, we will target the following Success Outcomes"
  );
  const showAllKPIsButton = s("report.kpis.showAllKPIs", "Show all KPIs");
  const hideKPIsButton = s("report.kpis.hideSomeKPIs", "Hide KPIs");
  const [showAllKPIs, setShowAllKPIs] = useState(false);

  const onToggleShowAllKPIs = () => {
    setShowAllKPIs(!showAllKPIs);
  };

  return isMobile ? (
    <MobileReportSection id={"report-kpis-impacted"}>
      <MobileReportTitle header={header} subheader={subheader} />
      <KPISummaryCards reportId={reportId} showAllKPIs={showAllKPIs} />
      {discoveryKPIs.length > 4 ? (
        <ButtonContainer>
          <NewButton type={"text"} onClick={onToggleShowAllKPIs}>
            {showAllKPIs ? hideKPIsButton : showAllKPIsButton}
          </NewButton>
        </ButtonContainer>
      ) : null}
    </MobileReportSection>
  ) : (
    <ReportKPIContainer id={"report-kpis-impacted"}>
      <ReportKPITitle level="h1" align="center">
        {header}
      </ReportKPITitle>
      <Heading level="h4" align="center" style={{ marginBottom: 16 }}>
        {subheader}
      </Heading>
      <KPISummaryCards reportId={reportId} />
    </ReportKPIContainer>
  );
};

ReportKPI.propTypes = {
  reportId: PropTypes.string.isRequired
};

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const ReportKPIContainer = styled(PageSection)`
  padding: 120px 24px;
  @media print {
    padding: 0px;
  }
`;

const ReportKPITitle = styled(Heading)`
  margin-bottom: 16px;
`;

const mapStateToProps = (state) => ({
  discoveryName: selectDiscoveryName(state),
  discoveryKPIs: selectDiscoverySelectedKPIs(state)
});

export default compose(connect(mapStateToProps))(ReportKPI);
