import types from "../actionTypes";

const INITIAL_STATE = {
  kpiCode: ""
};

const cloneKPIsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DISCOVERIES_FETCH_REQUEST:
    case types.DISCOVERY_FETCH_REQUEST:
      return INITIAL_STATE;

    case types.START_CLONING_KPI:
      return {
        ...state,
        kpiCode: action.payload.kpiCode
      };

    case types.STOP_CLONING_KPI:
      return {
        ...state,
        kpiCode: ""
      };

    default:
      return state;
  }
};

export default cloneKPIsReducer;

export const selectClonedKPICode = (data) => data.kpiCode;
