const mergeDefaults = (defaultObj, targetObj) => {
  const result = { ...targetObj };

  for (const key in defaultObj) {
    if (
      !Object.prototype.hasOwnProperty.call(result, key) ||
      result[key] === undefined
    ) {
      result[key] = defaultObj[key];
    }
  }

  return result;
};

export default mergeDefaults;
