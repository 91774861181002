import Form, { FormItem } from "../../../components/Form";
import { useString as s } from "../../../components/StringProvider";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ButtonGroup from "../../../components/ButtonGroup";
import NewButton from "../../../components/NewButton";
import {
  selectRequestData,
  selectRequestParameters,
  selectRequestState
} from "../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import { useMobileMediaQuery } from "../../../components/Responsive";
import { FileDragger, FileUpload } from "../ManageConfiguration/components";
import actionTypes from "../../../store/actionTypes";
import useLoadingState from "../../../utils/use-loading-state";
import { isEmpty } from "lodash";
import styled from "styled-components";
import ColorPicker from "./ColorPicker";

const UpdateBrandingThemesForm = ({
  form,
  initialValues,
  onFinish,
  submitButtonText,
  jobId,
  createFileData,
  createFileState,
  createFileParameters,
  clearFileLists = false,
  setAllFormValues,
  loading
}) => {
  const isMobile = useMobileMediaQuery();
  const [logoFileList, setLogoFileList] = useState([]);
  const [heroFileList, setHeroFileList] = useState([]);
  const [logoFileId, setLogoFileId] = useState();
  const [heroFileId, setHeroFileId] = useState();
  const [formReady, setFormReady] = useState(false);
  const [values, setValues] = useState({});
  const acceptTypes = ".jpg,.png,.webp";
  const maxFileSizeLogo = 25 * 1000;
  const maxFileSizeHero = 200 * 1000;

  useEffect(() => {
    setFormReady(false);
    setAllFormValues({ ...values, logoFileId, heroFileId });
    const hasDefinedValues =
      Object.values(values).filter((value) => value !== undefined).length > 0;
    if (logoFileId || heroFileId || hasDefinedValues) {
      setFormReady(true);
    }
  }, [values, logoFileId, heroFileId]);

  useEffect(() => {
    if (clearFileLists) {
      setLogoFileList([]);
      setHeroFileList([]);
    }
  }, [clearFileLists]);

  const primaryColorLabel = s(
    "organization.page.updateBranding.primaryColor.label",
    "Primary Colour"
  );
  const primaryColorPlaceholder = s(
    "organization.page.updateBranding.primaryColor.placeholder",
    "Select a Colour HEX code, i.e. #5057FE from the ColourPicker"
  );
  const secondaryColorLabel = s(
    "organization.page.updateBranding.secondaryColor.label",
    "Secondary Colour"
  );
  const secondaryColorPlaceholder = s(
    "organization.page.updateBranding.secondaryColor.placeholder",
    "Select a Colour HEX code, i.e. #5057FE from the ColourPicker"
  );
  const heroImageLabel = s(
    "organization.page.updateBranding.heroImage.label",
    "Hero Image"
  );
  const heroImageDescription = s(
    "organization.page.updateBranding.heroImage.description",
    "Required dimensions: Use a 800x800 or similar aspect ratio image. Square images with action focused in the center work best."
  );
  const logoImageLabel = s(
    "organization.page.updateBranding.logoImage.label",
    "Logo Image"
  );
  const logoImageDescription = s(
    "organization.page.updateBranding.logoImage.description",
    "Required dimensions: Use a 210x44 or similar aspect ratio image. Remove all whitespace from around the logo."
  );

  const maxWidth = "464px";

  const onStartUploadLogoFile = (file) => {
    setLogoFileList((list) => [...list, file]);
    return false;
  };

  const onDeleteLogoFile = (file) => {
    setLogoFileList((list) => list.filter((f) => f !== file));
    setLogoFileId(undefined);
  };

  const onLogoFileUploadComplete = () => {};

  const onStartUploadHeroFile = (file) => {
    setHeroFileList((list) => [...list, file]);
    return false;
  };

  const onDeleteHeroFile = (file) => {
    setHeroFileList((list) => list.filter((f) => f !== file));
    setHeroFileId(undefined);
  };

  const onHeroFileUploadComplete = () => {};

  useLoadingState(createFileState, () => {
    if (createFileParameters?.localTag === "logo") {
      setLogoFileId(createFileData?.file?._id);
    } else if (createFileParameters?.localTag === "hero") {
      setHeroFileId(createFileData?.file?._id);
    }
  });

  const onValuesChange = (changedValues) => {
    setValues({ ...values, ...changedValues });
  };

  return (
    <Form
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onFinish={onFinish}
      name={"updateBrandingForm"}
      onValuesChange={onValuesChange}
    >
      <FormItem
        name="primaryColor"
        label={primaryColorLabel}
        style={{ maxWidth }}
      >
        <ColorPicker />
      </FormItem>
      {!values.primaryColor && (
        <ColorPlaceholder>{primaryColorPlaceholder}</ColorPlaceholder>
      )}
      <FormItem
        name="secondaryColor"
        label={secondaryColorLabel}
        style={{ maxWidth }}
      >
        <ColorPicker />
      </FormItem>
      {!values.secondaryColor && (
        <ColorPlaceholder>{secondaryColorPlaceholder}</ColorPlaceholder>
      )}
      <FormItem label={logoImageLabel} style={{ maxWidth }}>
        {initialValues.logoFileName && (
          <FileName>{initialValues.logoFileName}</FileName>
        )}
        {logoFileList.length === 0 ? (
          <FileDragger
            acceptTypes={acceptTypes}
            maxFileSize={maxFileSizeLogo}
            multiple={false}
            onStartUpload={onStartUploadLogoFile}
            description={logoImageDescription}
          />
        ) : (
          logoFileList.map((file, index) => (
            <FileUpload
              localTag={"logo"}
              key={index}
              file={file}
              acceptTypes={acceptTypes}
              maxFileSize={maxFileSizeLogo}
              onDelete={onDeleteLogoFile}
              onUploadComplete={onLogoFileUploadComplete}
            />
          ))
        )}
      </FormItem>
      <FormItem label={heroImageLabel} style={{ maxWidth }}>
        {initialValues.heroFileName && (
          <FileName>{initialValues.heroFileName}</FileName>
        )}
        {heroFileList.length === 0 ? (
          <FileDragger
            acceptTypes={acceptTypes}
            maxFileSize={maxFileSizeHero}
            multiple={false}
            onStartUpload={onStartUploadHeroFile}
            description={heroImageDescription}
          />
        ) : (
          heroFileList.map((file, index) => (
            <FileUpload
              localTag={"hero"}
              acceptTypes={acceptTypes}
              maxFileSize={maxFileSizeHero}
              key={index}
              file={file}
              onDelete={onDeleteHeroFile}
              onUploadComplete={onHeroFileUploadComplete}
            />
          ))
        )}
      </FormItem>
      <FormItem>
        <ButtonGroup mobile={isMobile}>
          <NewButton
            type={"submit"}
            data-cy={"update-branding-save"}
            disabled={!formReady || !isEmpty(jobId)}
            loading={loading}
          >
            {submitButtonText}
          </NewButton>
        </ButtonGroup>
      </FormItem>
    </Form>
  );
};

const ColorPlaceholder = styled.div`
  margin-top: -15px;
  margin-bottom: 20px;
  opacity: 50%;
`;

const FileName = styled.div`
  padding-top: 5px;
  padding-bottom: 15px;
`;

UpdateBrandingThemesForm.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  createFileState: selectRequestState(state, actionTypes.CREATE_FILE_REQUEST),
  createFileData: selectRequestData(state, actionTypes.CREATE_FILE_REQUEST),
  createFileParameters: selectRequestParameters(
    state,
    actionTypes.CREATE_FILE_REQUEST
  )
});

export default compose(connect(mapStateToProps))(UpdateBrandingThemesForm);
