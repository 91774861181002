import {
  selectDiscovery,
  selectDiscoveryCurrency,
  selectDiscoveryId,
  selectDiscoveryKPI,
  selectRequestData,
  selectRequestError,
  selectRequestState
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useString as s, useStringTemplate } from "../../StringProvider";
import {
  cloneDiscoveryKPI,
  stopCloningKPI
} from "../../../store/actions/clone-kpis";
import Form from "../../Form";
import PropType from "prop-types";
import KPIModal from "./KPIModal";
import actionTypes from "../../../store/actionTypes";
import useLoadingState from "../../../utils/use-loading-state";
import Notification from "../../Notification";
import ExistingKPIForm from "./ExistingKPIForm";
import { IntlMessageFormat } from "intl-messageformat";
import { setScrollToKPICode } from "../../../store/actions/kpi-library";
import { getDiscoveryCommentCount } from "../../../store/actions/comments";

const CloneKPIModal = ({
  discoveryId,
  discovery,
  kpiCode,
  loadingState,
  error,
  discoveryKPI,
  cloneDiscoveryKPI,
  stopCloningKPI,
  setScrollToKPICode,
  getDiscoveryCommentCount,
  clonedKPIData,
  currency
}) => {
  const errorText = s("cloneKPI.popup.error.text", "Failed to create outcome");
  const successWithFormulaText = s(
    "cloneKPI.popup.successWithFormula.text",
    "Outcome cloned. The formula has been cloned and the inputs can be found on the Inputs page."
  );
  const successWithoutFormulaText = s(
    "cloneKPI.popup.successWithoutFormula.text",
    "Outcome cloned."
  );
  const nameTemplate = useStringTemplate(
    "cloneKPI.popup.name.defaultText",
    "{name} [copy{i, select, 1 {} other { {i}}}]"
  );
  const [form] = Form.useForm();
  const [order, setOrder] = useState(0);

  useEffect(() => {
    if (discovery && discoveryKPI) {
      const newOrder = discovery.kpis.reduce((min, dk) => {
        if (dk.order <= min) {
          return dk.order - 1;
        }

        return min;
      }, 100);
      setOrder(newOrder);

      let i = 1;
      let name = "";
      while (true) {
        name = new IntlMessageFormat(nameTemplate, "en-US").format({
          name: discoveryKPI?.definition?.name,
          i
        });

        if (!discovery.kpis.find((dk) => dk?.definition?.name === name)) {
          break;
        }
        i++;
      }

      form.resetFields();
      form.setFieldsValue({
        name,
        benefitValueDriverDescription:
          discoveryKPI?.definition?.benefitValueDriverDescription,
        detailText: discoveryKPI?.definition?.detailText
      });
    }
  }, [discoveryKPI, discovery]);

  useLoadingState(
    loadingState,
    () => {
      Notification.success(
        clonedKPIData.kpi.hasFormula
          ? successWithFormulaText
          : successWithoutFormulaText
      );
      stopCloningKPI();
      setScrollToKPICode({ kpiCode: clonedKPIData.kpi.kpiCode });
      getDiscoveryCommentCount({
        discoveryId
      });
    },
    () => {
      if (!error || error.status !== 409) {
        Notification.error(errorText);
      }
    }
  );

  const onFinish = ({
    name,
    benefitValueDriverDescription,
    categoryCode,
    detailText
  }) => {
    const data = {
      definition: {
        name,
        benefitValueDriverDescription,
        categoryCode,
        order,
        detailText
      }
    };

    cloneDiscoveryKPI({
      discoveryId,
      kpiCode,
      data
    });
  };

  return discoveryKPI && discovery ? (
    <KPIModal open={true} data-cy={"clone-kpi-modal"}>
      <ExistingKPIForm
        discoveryKPI={discoveryKPI}
        currency={currency}
        onFinish={onFinish}
        onCancel={stopCloningKPI}
        form={form}
        error={error}
      />
    </KPIModal>
  ) : null;
};

CloneKPIModal.propTypes = {
  kpiCode: PropType.string.isRequired
};

const mapStateToProps = (state, props) => ({
  discoveryId: selectDiscoveryId(state),
  loadingState: selectRequestState(state, actionTypes.CLONE_KPI_REQUEST),
  clonedKPIData: selectRequestData(state, actionTypes.CLONE_KPI_REQUEST),
  error: selectRequestError(state, actionTypes.CLONE_KPI_REQUEST),
  discoveryKPI: selectDiscoveryKPI(state, props.kpiCode),
  currency: selectDiscoveryCurrency(state),
  discovery: selectDiscovery(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      cloneDiscoveryKPI,
      stopCloningKPI,
      setScrollToKPICode,
      getDiscoveryCommentCount
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CloneKPIModal
);
