import { Space } from "antd";
import CategoryBadges from "../CategoryBadges";
import React from "react";
import PropType from "prop-types";
import KPIData from "./KPIData";
import {
  SelectableCardLeftColumn,
  SelectableCardRightColumn,
  SelectableCardRow
} from "../SelectableCard";
import AboutThisKPI from "../KPIModal/AboutThisKPI";

const StandardDetails = ({
  discoveryKPI,
  reportId,
  currency,
  editable,
  selected,
  onChange,
  onChangeImportance,
  printMode
}) => {
  return (
    <>
      <SelectableCardRow>
        <SelectableCardLeftColumn>
          <Space direction={"vertical"} size={9} style={{ width: "100%" }}>
            <div data-cy={"benefit-value-driver-description"}>
              {discoveryKPI.definition.benefitValueDriverDescription}
            </div>
            <CategoryBadges definition={discoveryKPI.definition} />
          </Space>
        </SelectableCardLeftColumn>

        <SelectableCardRightColumn>
          {selected ? (
            <KPIData
              discoveryKPI={discoveryKPI}
              currency={currency}
              isEditable={editable}
              onChange={onChange}
              onChangeImportance={onChangeImportance}
            />
          ) : null}
        </SelectableCardRightColumn>
      </SelectableCardRow>
      {(discoveryKPI.definition.detailText ||
        discoveryKPI.relatedChallengeCodes.length) &&
      selected ? (
        <SelectableCardRow>
          <AboutThisKPI
            discoveryKPI={discoveryKPI}
            reportId={reportId}
            style={{ marginTop: 9 }}
            printMode={printMode}
          />
        </SelectableCardRow>
      ) : null}
    </>
  );
};

StandardDetails.propTypes = {
  discoveryKPI: PropType.object.isRequired,
  currency: PropType.string.isRequired,
  editable: PropType.bool.isRequired,
  reportId: PropType.string,
  onChange: PropType.func,
  onChangeImportance: PropType.func,
  printMode: PropType.bool
};

export default StandardDetails;
