import React, { useEffect, useState } from "react";
import Modal from "../../Modal";
import { stopEditingKPI } from "../../../store/actions/edit-kpis";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  selectDiscoveryCurrency,
  selectDiscoveryId,
  selectDiscoveryKPI,
  selectEditKPICode,
  selectRequestData,
  selectDiscovery,
  selectRequestState,
  selectRequestParameters
} from "../../../store/reducers";
import { previewDiscoveryKPI } from "../../../store/actions/discoveries";
import actionTypes from "../../../store/actionTypes";
import EditQuantifiedKPIForm from "./EditQuantifiedKPIForm";
import useLoadingState from "../../../utils/use-loading-state";
import { isQuantifiedKPI } from "../../../utils/filters/kpi-filters";

const EditQuantifiedKPIModal = ({
  stopEditingKPI,
  previewData,
  discoveryKPI,
  discoveryId,
  kpiCode,
  previewDiscoveryKPI,
  discoveryKPIPreviewLoadingState,
  kpiCodePreview
}) => {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    setPreview(null);
  }, [kpiCode]);

  useEffect(() => {
    if (kpiCode && discoveryKPI.hasImpactValue)
      previewDiscoveryKPI({
        discoveryId,
        kpiCode,
        kpiChanges: {},
        metricChanges: []
      });
  }, [kpiCode, discoveryId, discoveryKPI]);

  useLoadingState(
    discoveryKPIPreviewLoadingState,
    () => {
      if (kpiCodePreview === kpiCode) {
        setPreview(previewData);
      }
    },
    () => {}
  );

  const onCancel = () => {
    stopEditingKPI();
  };

  return preview && discoveryKPI && isQuantifiedKPI({ discoveryKPI }) ? (
    <Modal
      open={true}
      onCancel={onCancel}
      data-cy={"edit-quantified-kpi-value-modal"}
      title={discoveryKPI?.definition?.name}
      titleSize={"h3"}
      width={"760px"}
    >
      <EditQuantifiedKPIForm
        discoveryKPI={preview?.discovery.kpis.find(
          (kpi) => kpi.kpiCode === kpiCode
        )}
        formulaDescription={preview?.formulaDescription}
        onClose={onCancel}
      />
    </Modal>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    discovery: selectDiscovery(state),
    discoveryId: selectDiscoveryId(state),
    kpiCode: selectEditKPICode(state),
    discoveryKPI: selectDiscoveryKPI(state, selectEditKPICode(state)),
    currency: selectDiscoveryCurrency(state),
    kpiCodePreview: selectRequestParameters(
      state,
      actionTypes.DISCOVERY_PREVIEW_KPI_REQUEST
    )?.kpiCode,
    previewData: selectRequestData(
      state,
      actionTypes.DISCOVERY_PREVIEW_KPI_REQUEST
    ),
    discoveryKPIPreviewLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_PREVIEW_KPI_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      stopEditingKPI,
      previewDiscoveryKPI
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EditQuantifiedKPIModal);
