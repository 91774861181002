import {
  hasPermission,
  selectAllGroups,
  selectDiscoveryId,
  selectDiscoveryKPIGroupCodeString,
  selectDiscoverySetting,
  selectDiscoveryUngroupedKPIs,
  selectRequestState,
  selectScrollToKPICode,
  selectIsCreatingKPI,
  selectRequestParameters,
  selectDiscoveryKPIs,
  selectRequestData,
  selectClonedKPICode
} from "../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import KPICardAndModals from "../../components/Discovery/KPICard/KPICardAndModals";
import KPIGroup from "./KPIGroup";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Space } from "antd";
import { useMobileMediaQuery } from "../../components/Responsive";
import useLoadingState from "../../utils/use-loading-state";
import { scrollToSection } from "../Report/components/Header/ScrollUtils";
import actionTypes from "../../store/actionTypes";
import ExpandedOrCollapsed from "../../utils/ExpandedOrCollapsed";
import { updateDiscoverySettingsGroup } from "../../store/actions/discoveries";
import {
  clearScrollToKPICode,
  setScrollToKPICode
} from "../../store/actions/kpi-library";
import { CreateKPIModal } from "../../components/Discovery/KPIModal";
import Permissions from "../../utils/permissions";
import { isDiscoveryGroupCollapsed } from "../../store/selectors/discovery";
import LoadingState from "../../utils/loading-state";
import CloneKPIModal from "../../components/Discovery/KPIModal/CloneKPIModal";

const ViewKPIsByGroup = ({
  discoveryId,
  discoveryKPIsUngrouped,
  groupCodeString,
  groups,
  isCreatingKPI,
  clonedKPICode,
  canSeeKPILibrary,
  groupExpandedLoadingState,
  updateDiscoverySettingsGroup,
  addDiscoveryKPILoadingState,
  addDiscoveryKPIParameters,
  setScrollToKPICode,
  clearScrollToKPICode,
  scrollToKPICode,
  scrollToGroupCode,
  isScrollToGroupCollapsed,
  createKPILoadingState,
  createKPIData
}) => {
  const isMobile = useMobileMediaQuery();

  const toggleGroupExpandedState = ({ groupCode }) => {
    updateDiscoverySettingsGroup({
      discoveryId,
      groupId: groupCode,
      status: ExpandedOrCollapsed.EXPANDED
    });
  };

  useLoadingState(
    addDiscoveryKPILoadingState,
    () => {
      const kpiCode = addDiscoveryKPIParameters.kpiCode;
      setScrollToKPICode({ kpiCode });
    },
    () => {}
  );

  useLoadingState(
    createKPILoadingState,
    () => {
      const kpiCode = createKPIData.kpiCode;
      setTimeout(() => {
        scrollToSection({ sectionId: `kpi-card-${kpiCode}` });
      }, 100);
    },
    () => {}
  );

  useEffect(() => {
    if (scrollToKPICode && isScrollToGroupCollapsed) {
      toggleGroupExpandedState({ groupCode: scrollToGroupCode });
    } else if (
      scrollToKPICode &&
      !isScrollToGroupCollapsed &&
      (groupExpandedLoadingState === LoadingState.COMPLETED ||
        groupExpandedLoadingState === LoadingState.UNINITIALIZED)
    ) {
      scrollToSection({ sectionId: `kpi-card-${scrollToKPICode}` });
      clearScrollToKPICode();
    }
  }, [scrollToKPICode, isScrollToGroupCollapsed, groupExpandedLoadingState]);

  const groupCodes = groupCodeString ? groupCodeString.split(",") : [];
  const sortedGroups = groups.filter((group) =>
    groupCodes.includes(group.code)
  );
  sortedGroups.sort((a, b) => a.order - b.order);

  return (
    <>
      <Space direction={"vertical"} size={isMobile ? 8 : 14}>
        {isCreatingKPI && canSeeKPILibrary && <CreateKPIModal />}
        {clonedKPICode && canSeeKPILibrary && (
          <CloneKPIModal kpiCode={clonedKPICode} />
        )}
        {discoveryKPIsUngrouped.length
          ? discoveryKPIsUngrouped.map((discoveryKPI) => (
              <KPICardAndModals
                discoveryKPI={discoveryKPI}
                key={discoveryKPI.kpiCode}
              />
            ))
          : null}
      </Space>

      <Space direction={"vertical"} size={20}>
        {groupCodeString ? (
          <Groups>
            {sortedGroups.map((group) => (
              <KPIGroup
                key={group.code}
                groupCode={group.code}
                initiallyCollapsed={group.collapsed}
              />
            ))}
          </Groups>
        ) : (
          ""
        )}
      </Space>
    </>
  );
};

const Groups = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const mapStateToProps = (state) => {
  const showKPIs = selectDiscoverySetting(state, "showKPIs", "all");

  const scrollToKPICode = selectScrollToKPICode(state);
  const discoveryKPIs = selectDiscoveryKPIs(state);
  const scrollToGroupCode =
    scrollToKPICode &&
    discoveryKPIs.find((kpi) => kpi.kpiCode === scrollToKPICode)?.definition
      ?.groupCode;
  const isScrollToGroupCollapsed =
    scrollToGroupCode &&
    isDiscoveryGroupCollapsed(
      selectDiscoverySetting(state, "groups"),
      scrollToGroupCode
    );

  return {
    discoveryId: selectDiscoveryId(state),
    discoveryKPIsUngrouped: selectDiscoveryUngroupedKPIs(state, showKPIs),
    groupCodeString: selectDiscoveryKPIGroupCodeString(state, showKPIs),
    groups: selectAllGroups(state),
    addDiscoveryKPILoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_ADD_KPI_FROM_LIBRARY_REQUEST
    ),
    addDiscoveryKPIParameters: selectRequestParameters(
      state,
      actionTypes.DISCOVERY_ADD_KPI_FROM_LIBRARY_REQUEST
    ),
    groupExpandedLoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_UPDATE_SETTINGS_GROUP_REQUEST
    ),
    scrollToKPICode,
    scrollToGroupCode,
    isScrollToGroupCollapsed,
    isCreatingKPI: selectIsCreatingKPI(state),
    clonedKPICode: selectClonedKPICode(state),
    canSeeKPILibrary: hasPermission(state, Permissions.SEE_KPI_LIBRARY),
    createKPILoadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_CREATE_KPI_REQUEST
    ),
    createKPIData: selectRequestData(
      state,
      actionTypes.DISCOVERY_CREATE_KPI_REQUEST
    )
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDiscoverySettingsGroup,
      setScrollToKPICode,
      clearScrollToKPICode
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ViewKPIsByGroup
);
