import types from "../actionTypes";
import discoveriesService from "../../services/discoveries.service";

export const startCloningKPI =
  ({ kpiCode }) =>
  (dispatch) => {
    dispatch({
      type: types.START_CLONING_KPI,
      payload: { kpiCode }
    });
  };

export const stopCloningKPI = () => (dispatch) => {
  dispatch({
    type: types.STOP_CLONING_KPI
  });
};

export const cloneDiscoveryKPI =
  ({ discoveryId, kpiCode, data }) =>
  (dispatch) => {
    dispatch({
      type: types.CLONE_KPI_REQUEST
    });

    return discoveriesService
      .cloneDiscoveryKPI({ discoveryId, kpiCode, data })
      .then((payload) => {
        dispatch({
          type: types.CLONE_KPI_SUCCESS,
          payload
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.CLONE_KPI_FAILURE,
          error
        });
      });
  };
