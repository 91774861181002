import { Route, Switch } from "react-router-dom";
import { NotFound } from "../ErrorPage";
import React from "react";
import ManageConfiguration from "./ManageConfiguration";
import AccessManagement from "./AccessManagement";
import General from "./General";
import { useFeature } from "../../components/FeaturesProvider";
import UploadCRMData from "./UploadCRMData";
import UpdateBranding from "./UpdateBranding";
import DeploymentJob from "./DeploymentJob";
import Autoconfiguration from "./Autoconfiguration";
import { selectIsCuvamaUser } from "../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import CuvamaRoute from "../../routes/CuvamaRoute";
import Permissions from "../../utils/permissions";
import { IntegrationCallback } from "./General/Integration";
import CuvamaAI from "./CuvamaAI";

const Organization = ({ isCuvamaUser }) => {
  const crmAccountsIntegration = useFeature("crmAccountsIntegration", false);
  const salesforceIntegration = useFeature("salesforceIntegration", false);
  const hubspotIntegration = useFeature("hubspotIntegration", false);

  return (
    <Switch>
      <Route
        path="/organizations/manage-configuration"
        component={ManageConfiguration}
      />
      <Route
        path="/organizations/access-management"
        component={AccessManagement}
      />
      <Route
        path="/organizations/general/salesforce-connection-callback"
        exact
        component={IntegrationCallback}
      />
      {(salesforceIntegration || hubspotIntegration || isCuvamaUser) && (
        <Route path="/organizations/general" component={General} />
      )}

      {crmAccountsIntegration || salesforceIntegration ? (
        <Route
          path="/organizations/upload-crm-data"
          component={UploadCRMData}
        />
      ) : null}
      <Route path="/organizations/customisation" component={UpdateBranding} />
      <Route
        path="/organizations/deployments/:jobId"
        component={DeploymentJob}
        exact
      />
      <Route
        path="/organizations/autoconfigurations"
        component={Autoconfiguration}
      />

      <CuvamaRoute
        path="/organizations/cuvama-ai"
        component={CuvamaAI}
        requiresPermission={Permissions.SEE_AI_EMAIL_TEMPLATES}
        requiresCuvamaUser={true}
      />

      <Route component={NotFound} />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  isCuvamaUser: selectIsCuvamaUser(state)
});

export default compose(connect(mapStateToProps))(Organization);
