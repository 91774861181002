import OrganizationHeader from "../OrganizationHeader";
import { OrganizationPageLayout } from "../../../components/Layout";
import { Pages as OrganisationPages } from "../../../components/Layout/OrganizationPageLayout";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import Page from "../../../components/Page";
import React, { useEffect, useState } from "react";
import { useString as s } from "../../../components/StringProvider";
import styled from "styled-components";
import Heading from "../../../components/Heading";
import NavigationMenu from "../../../components/NavigationMenu";
import Strings from "./Strings";
import Branding from "./Branding";

const Pages = {
  BRANDING: "branding",
  STRINGS: "strings"
};

Pages.ALL = Object.values(Pages);

const getSelectedPage = (pathname) => {
  for (const page of Pages.ALL) {
    if (pathname.endsWith(page)) {
      return page;
    }
  }

  return Pages.BRANDING;
};

const UpdateBranding = ({ history }) => {
  const location = useLocation();
  const [selectedPage, setSelectedPage] = useState(Pages.BRANDING);

  const navigationMenuItems = [
    {
      key: Pages.BRANDING,
      label: "Branding"
    },
    {
      key: Pages.STRINGS,
      label: "Strings"
    }
  ];

  const heading = s(
    "organization.page.updateBranding.page.title",
    "Customisation"
  );

  useEffect(() => {
    setSelectedPage(getSelectedPage(location.pathname));
  }, [location.pathname]);

  const onChangePage = (e) => {
    history.push(`/organizations/${OrganisationPages.CUSTOMISATION}/${e.key}`);
  };

  return (
    <Page header={<OrganizationHeader />}>
      <OrganizationPageLayout>
        <Heading level={"h3"}>{heading}</Heading>
        <NavigationMenu
          mode={"horizontal"}
          selectedKeys={[selectedPage]}
          onClick={onChangePage}
          items={navigationMenuItems}
        />
        <Container>
          <Switch>
            <Route
              path={`/organizations/${OrganisationPages.CUSTOMISATION}/${Pages.BRANDING}`}
              exact
              component={Branding}
            />
            <Route
              path={`/organizations/${OrganisationPages.CUSTOMISATION}/${Pages.STRINGS}`}
              exact
              component={Strings}
            />
            <Redirect
              from=""
              to={`/organizations/${OrganisationPages.CUSTOMISATION}/${Pages.BRANDING}`}
              exact
            />
          </Switch>
        </Container>
      </OrganizationPageLayout>
    </Page>
  );
};

const Container = styled.div`
  width: 100%;
`;

export default UpdateBranding;
