import React from "react";
import TextBody from "../../../components/TextBody";
import NewButton from "../../../components/NewButton";
import { useString as s } from "../../../components/StringProvider";
import ButtonGroup from "../../../components/ButtonGroup";
import { selectDiscoveryId } from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { deleteDiscoveryKPI } from "../../../store/actions/discoveries";
import { stopDeletingKPI } from "../../../store/actions/delete-kpis";
import { selectDeleteKPICode } from "../../../store/reducers";
import Modal from "../../Modal";

const DeleteKPIModal = ({
  discoveryId,
  kpiCode,
  deleteDiscoveryKPI,
  stopDeletingKPI
}) => {
  const title = s("discovery.kpi.card.delete.modal.title", "Delete Outcome");
  const text = s(
    "discovery.kpi.card.delete.modal.text",
    "By continuing, your custom outcome will be removed from the discovery and deleted"
  );
  const ok = s("discovery.kpi.card.delete.modal.ok", "Delete Outcome");
  const cancel = s("discovery.kpi.card.delete.modal.cancel", "Keep Outcome");

  if (!kpiCode) {
    return null;
  }

  const onClose = () => {
    stopDeletingKPI();
  };

  const onConfirm = () => {
    deleteDiscoveryKPI({ discoveryId, kpiCode });
  };
  return (
    <Modal
      title={title}
      open={true}
      onCancel={onClose}
      data-cy={"delete-kpi-modal"}
    >
      <TextBody>{text}</TextBody>
      <div style={{ paddingTop: 24 }}>
        <ButtonGroup>
          <NewButton type={"primary"} onClick={onConfirm} data-cy={"ok-button"}>
            {ok}
          </NewButton>
          <NewButton
            type={"secondary"}
            onClick={onClose}
            data-cy={"cancel-button"}
          >
            {cancel}
          </NewButton>
        </ButtonGroup>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  kpiCode: selectDeleteKPICode(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteDiscoveryKPI,
      stopDeletingKPI
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DeleteKPIModal
);
