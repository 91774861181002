import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { compose } from "redux";
import { DiscoveryFooter, DiscoveryTitleBar } from "../../components/Discovery";
import MetricWidget from "./components/MetricWidget";
import { useString as s } from "../../components/StringProvider";
import {
  hasPermission,
  selectAllGroups,
  selectDiscoveryCurrency,
  selectDiscoveryGroups,
  selectDiscoveryId,
  selectDiscoveryMetricGroupCodeString,
  selectDiscoveryMetrics,
  selectDiscoveryResetMetricError,
  selectDiscoveryUngroupedMetricCodeString
} from "../../store/reducers";
import MetricGroup from "./components/MetricGroup";
import Heading from "../../components/Heading";
import Permissions from "../../utils/permissions";
import ValidationTypes from "../../utils/validation-types";
import DiscoveryContentWrapper from "../Discovery/DiscoveryContentWrapper";
import { GoToValueHypothesisButton } from "../../components/DiscoveryButtons";
import { useMobileMediaQuery } from "../../components/Responsive";
import Text from "../../components/Text";
import Notification from "../../components/Notification";

const isDiscoveryMetricVisible =
  ({ canSeeInvisibleMetrics }) =>
  (discoveryMetric) =>
    discoveryMetric.visible || canSeeInvisibleMetrics;
const isDiscoveryMetricUngrouped = (discoveryMetric) =>
  !discoveryMetric.groupCode;

const BusinessMetrics = ({
  discoveryId,
  currency,
  metricCodeString,
  discoveryMetrics,
  discoveryGroups,
  resetMetricError,
  canSeeInvisibleMetrics
}) => {
  const isMobile = useMobileMediaQuery();
  const resetMetricErrorMessage = s(
    "discovery.metrics.reset.error",
    "Failed to reset metric"
  );
  const emptyPageText = s(
    "discovery.metrics.page.empty.text",
    "You have not selected any outcomes that need additional inputs in order to evaluate their financial impact.  Please click Next to proceed."
  );
  const [ungroupedMetricCodes, setUngroupedMetricCodes] = useState("");
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const list = discoveryMetrics
      .filter(isDiscoveryMetricVisible({ canSeeInvisibleMetrics }))
      .filter(isDiscoveryMetricUngrouped);

    list.sort((a, b) => a.order - b.order);
    setUngroupedMetricCodes(list.map((metric) => metric.metricCode).join(","));
  }, [discoveryMetrics, canSeeInvisibleMetrics]);

  useEffect(() => {
    const newGroupCodes = _.uniq(
      discoveryMetrics
        .filter(isDiscoveryMetricVisible({ canSeeInvisibleMetrics }))
        .map((discoveryMetric) => discoveryMetric.groupCode)
        .filter((groupCode) => groupCode)
    );

    const newGroups = newGroupCodes.map((groupCode) =>
      discoveryGroups.find(
        (discoveryGroup) => discoveryGroup.groupCode === groupCode
      )
    );

    newGroups.sort((a, b) => a.definition.order - b.definition.order);
    setGroups(newGroups);
  }, [discoveryMetrics, discoveryGroups, canSeeInvisibleMetrics]);

  useEffect(() => {
    if (resetMetricError) {
      Notification.error(resetMetricErrorMessage);
    }
  }, [resetMetricError]);

  const hasNoErrors = !discoveryGroups.some(
    (discoveryGroup) =>
      discoveryGroup?.status?.validationType === ValidationTypes.ERROR
  );

  const hasMetrics = ungroupedMetricCodes || groups.length;

  return (
    <DiscoveryContentWrapper isMobile={isMobile}>
      <DiscoveryTitleBar>
        <Header className={isMobile ? "mobile" : undefined}>
          <Heading level={isMobile ? "h3" : "h2"}>
            {s("discovery.metrics.page.header", "Your Situation")}
          </Heading>
          {hasMetrics && (
            <SubHeader>
              <Text variant={isMobile ? "bodyMobile" : "body"}>
                {s(
                  "discovery.metrics.page.subheader",
                  "Please review these industry averages and optionally adjust the assumptions"
                )}
              </Text>
            </SubHeader>
          )}
        </Header>
      </DiscoveryTitleBar>

      {ungroupedMetricCodes ? (
        <Metrics>
          {ungroupedMetricCodes.split(",").map((metricCode, index) => (
            <MetricWidget
              key={index}
              discoveryId={discoveryId}
              metricCode={metricCode}
              currency={currency}
            />
          ))}
        </Metrics>
      ) : (
        ""
      )}

      {groups.length ? (
        <Groups>
          {groups.map((group, index) =>
            !metricCodeString ? (
              <MetricGroup
                key={group.groupCode}
                groupCode={group.groupCode}
                initiallyCollapsed={index === 0 && group.definition.collapsed}
              />
            ) : (
              <MetricGroup
                key={group.groupCode}
                groupCode={group.groupCode}
                initiallyCollapsed={group.definition.collapsed}
              />
            )
          )}
        </Groups>
      ) : null}

      {!hasMetrics && <span>{emptyPageText}</span>}

      <DiscoveryFooter>
        {hasNoErrors || !hasMetrics ? <GoToValueHypothesisButton /> : null}
      </DiscoveryFooter>
    </DiscoveryContentWrapper>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: column;

  &.mobile {
    text-align: center;
  }

  &.mobile h3 {
    text-align: center;
    margin-bottom: 8px;
  }
`;

const SubHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const Metrics = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Groups = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`;

const mapStateToProps = (state) => ({
  discoveryId: selectDiscoveryId(state),
  currency: selectDiscoveryCurrency(state),
  canSeeInvisibleMetrics: hasPermission(state, Permissions.SEE_ALL_METRICS),
  metricCodeString: selectDiscoveryUngroupedMetricCodeString(
    state,
    hasPermission(state, Permissions.SEE_ALL_METRICS)
  ),
  groupCodeString: selectDiscoveryMetricGroupCodeString(
    state,
    hasPermission(state, Permissions.SEE_ALL_METRICS)
  ),
  resetMetricError: selectDiscoveryResetMetricError(state),
  groups: selectAllGroups(state),
  discoveryGroups: selectDiscoveryGroups(state),
  discoveryMetrics: selectDiscoveryMetrics(state)
});

export default compose(connect(mapStateToProps))(BusinessMetrics);
