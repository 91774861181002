import React, { useContext } from "react";
import styled from "styled-components";
import { useString as s } from "../../../components/StringProvider";
import { Text } from "../../../components";
import SubtotalTableRow from "./SubtotalTableRow";
import * as PropTypes from "prop-types";
import { SettingsContext } from "../../../components/SettingsProvider";
import { getMetricFormatter } from "../../../utils/formatting";
import { DesktopOrTablet, Mobile } from "../../../components/Responsive";
import SubtotalRow from "./SubtotalRow";

const SubtotalTable = ({
  discoveryId,
  currency,
  discoveryGroup,
  metricCodeString
}) => {
  const totalText = s("discovery.metrics.subtotal.total", "Total");
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;

  const formatter = getMetricFormatter({
    type: discoveryGroup.status.type,
    locale,
    currency
  });

  return (
    <Container>
      <DesktopOrTablet>
        <table>
          <tbody>
            {metricCodeString.split(",").map((metricCode) => (
              <SubtotalTableRow
                key={metricCode}
                discoveryId={discoveryId}
                currency={currency}
                metricCode={metricCode}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>
                <Text variant={"bMedium"}>{totalText}</Text>
              </td>
              <td className={"total"}>
                <Text variant={"bMedium"}>
                  {discoveryGroup.status
                    ? formatter(discoveryGroup.status.value)
                    : ""}
                </Text>
              </td>
            </tr>
          </tfoot>
        </table>
      </DesktopOrTablet>
      <Mobile>
        <MobileSubtotalContainer>
          {metricCodeString.split(",").map((metricCode) => (
            <SubtotalRow
              key={metricCode}
              discoveryId={discoveryId}
              currency={currency}
              metricCode={metricCode}
            />
          ))}
          <Text variant={"bMediumMobile"}>
            {totalText}{" "}
            {discoveryGroup.status
              ? formatter(discoveryGroup.status.value)
              : ""}
          </Text>
        </MobileSubtotalContainer>
      </Mobile>
      {discoveryGroup.status.validationMessage ? (
        <Text color={discoveryGroup.status.validationType}>
          {discoveryGroup.status.validationMessage}
        </Text>
      ) : null}
    </Container>
  );
};

SubtotalTable.propTypes = {
  discoveryId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  metricCodeString: PropTypes.string.isRequired,
  discoveryGroup: PropTypes.object.isRequired
};

const Container = styled.div`
  & table {
    width: 100%;
  }

  & tr {
    height: 52px;
  }

  & td.total {
    padding-left: 11px;
  }

  & tr td:nth-child(1) {
    width: 100%;
  }

  & tr td:nth-child(2) {
    width: 160px;
    margin-left: 50px;
  }

  & tr td:nth-child(3) {
    min-width: 40px;
  }

  & .ant-input-number {
    width: 160px;

    input {
      height: 60px;
      width: 160px;
    }
  }

  & tbody tr:last-child .ant-input-number input {
    border-bottom-width: 2px;
  }
`;

const MobileSubtotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > * {
    margin-bottom: 24px;
  }
`;

export default SubtotalTable;
