import PropTypes from "prop-types";
import Icon from "../../../components/Icon";
import NewButton from "../../../components/NewButton";
import React from "react";
import styled from "styled-components";

const ResetButton = ({ tooltipText, visible, onReset }) => {
  return (
    <Container>
      {visible ? (
        <NewButton
          type={"iconSecondary"}
          tooltip={tooltipText}
          onClick={onReset}
          data-cy={"reset-button"}
        >
          <Icon name={"reset"} />
        </NewButton>
      ) : null}
    </Container>
  );
};

ResetButton.propTypes = {
  tooltipText: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onReset: PropTypes.func.isRequired
};

const Container = styled.div`
  min-width: 40px;
`;

export default ResetButton;
