import React, { createContext, useContext, useEffect } from "react";
import { connect } from "react-redux";
import {
  selectDiscoveryTypeCode,
  selectRequestData,
  selectRequestError,
  selectRequestState
} from "../store/reducers";
import { IntlMessageFormat } from "intl-messageformat";
import { bindActionCreators } from "redux";
import { getStrings } from "../store/actions/strings";
import actionTypes from "../store/actionTypes";
import useLoadingState from "../utils/use-loading-state";
import defaultStrings from "../utils/defaults/default-strings";
import mergeDefaults from "../utils/defaults/merge-defaults";

export const StringContext = createContext();
export const StringProvider = ({
  children,
  discoveryTypeCode,
  strings,
  loadingState,
  getStrings,
  error
}) => {
  useEffect(() => {
    getStrings();
  }, []);

  useLoadingState(
    loadingState,
    () => {},
    () => {
      console.error("string retrieval failed", error);
    }
  );

  return (
    <StringContext.Provider
      value={{
        strings,
        discoveryTypeCode
      }}
    >
      {children}
    </StringContext.Provider>
  );
};

const getVariables = () => {
  const { strings, discoveryTypeCode } = useContext(StringContext);
  const variables = {};

  for (const [key, value] of Object.entries(strings)) {
    const baseRegex = /^variables\.(.*)$/;
    const matches = key.match(baseRegex);

    if (matches && matches[1]) {
      variables[matches[1]] = value;
    }
  }

  if (discoveryTypeCode) {
    for (const [key, value] of Object.entries(strings)) {
      const discoveryRegex = new RegExp(
        `^discovery_types\\.${discoveryTypeCode}\\.variables\\.(.*)$`
      );
      const matches = key.match(discoveryRegex);

      if (matches && matches[1] && value) {
        variables[matches[1]] = value;
      }
    }
  }

  return mergeDefaults(defaultStrings.strings.variables, variables);
};

export const useStringTemplate = (
  key,
  defaultString,
  previewDiscoveryTypeCode
) => {
  const { strings, discoveryTypeCode } = useContext(StringContext);

  let template = undefined;
  const _discoveryTypeCode = discoveryTypeCode || previewDiscoveryTypeCode;

  if (_discoveryTypeCode) {
    template = strings[`discovery_types.${_discoveryTypeCode}.${key}`];
  }

  if (template === undefined) {
    template = strings[key];
  }

  if (template === undefined) {
    template = defaultString !== undefined ? defaultString : key;
  }

  return template;
};

export const useString = (
  key,
  defaultString,
  placeholders = null,
  previewDiscoveryTypeCode
) => {
  const string = useStringTemplate(
    key,
    defaultString,
    previewDiscoveryTypeCode
  );
  const variables = getVariables();

  try {
    return new IntlMessageFormat(string, "en-US").format({
      ...placeholders,
      ...variables
    });
  } catch (e) {
    return string;
  }
};

const mapStateToProps = (state) => ({
  discoveryTypeCode: selectDiscoveryTypeCode(state),
  strings: selectRequestData(state, actionTypes.GET_STRINGS_REQUEST) || {},
  loadingState: selectRequestState(state, actionTypes.GET_STRINGS_REQUEST),
  error: selectRequestError(state, actionTypes.GET_STRINGS_REQUEST)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getStrings
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StringProvider);
