import { withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { selectRequestData, selectRequestState } from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import actionTypes from "../../../store/actionTypes";
import UpdateBrandingForm from "./UpdateBrandingThemesForm";
import { getTheme } from "../../../store/actions/themes";
import Form from "../../../components/Form";
import { updateBrandingThemes } from "../../../store/actions/configurations";
import useLoadingState from "../../../utils/use-loading-state";
import { Space } from "antd";
import { useString as s } from "../../../components/StringProvider";
import styled from "styled-components";
import Text from "../../../components/Text";
import JobStatuses from "../../../utils/job-statuses";
import JobMonitor from "../../../components/JobMonitor";
import Notification from "../../../components/Notification";

const Branding = ({
  theme,
  updateBrandingThemes,
  updateBrandingThemesJob,
  updateBrandingThemesLoadingState,
  getTheme
}) => {
  const [formThemes] = Form.useForm();
  const [allFormValues, setAllFormValues] = useState([]);
  const [jobId, setJobId] = useState("");
  const [job, setJob] = useState({ status: JobStatuses.COMPLETED });
  const [clearFileLists, setClearFileLists] = useState(false);

  const themesTitle = s(
    "organization.page.updateBranding.themes.title",
    "THEMES"
  );
  const themesSaveText = s(
    "organization.page.updateBranding.themes.save",
    "Save Themes"
  );
  const updateBrandingThemesSuccess = s(
    "organization.page.updateBranding.popup.messages.themesSuccess",
    "The Theme has been updated successfully"
  );
  const updateBrandingThemesFailure = s(
    "organization.page.updateBranding.popup.messages.themesFailure",
    "The Themes update failed"
  );

  useEffect(() => {
    getTheme();
  }, []);

  const onSubmitThemes = () => {
    updateBrandingThemes(allFormValues);
  };

  const getThemesFormInitialValues = ({ theme }) => {
    return {
      primaryColor: theme && theme.palette.primary,
      secondaryColor: theme && theme.palette.secondary,
      heroFileName: theme && theme.assets.login_cover,
      logoFileName: theme && theme.assets.logo
    };
  };

  useLoadingState(updateBrandingThemesLoadingState, () => {
    setJobId(updateBrandingThemesJob._id);
  });

  const onJobFinish = (successful) => {
    if (successful) {
      setClearFileLists(true);
      getTheme();
      Notification.success(updateBrandingThemesSuccess);
    } else {
      Notification.error(updateBrandingThemesFailure);
    }
    setJobId("");
  };

  if (!theme) {
    return null;
  }

  return (
    <ThemesContainer>
      <Space direction={"vertical"} size={16}>
        <Text variant={"h5"} color={"gray4"}>
          {themesTitle}
        </Text>
        <UpdateBrandingForm
          form={formThemes}
          initialValues={getThemesFormInitialValues({ theme })}
          onFinish={onSubmitThemes}
          submitButtonText={themesSaveText}
          jobId={jobId}
          clearFileLists={clearFileLists}
          setAllFormValues={setAllFormValues}
          loading={
            job?.status !== JobStatuses.FAILED &&
            job?.status !== JobStatuses.COMPLETED
          }
        />
      </Space>
      {jobId && (
        <JobMonitor jobId={jobId} onChange={setJob} onFinish={onJobFinish} />
      )}
    </ThemesContainer>
  );
};

const ThemesContainer = styled.section``;

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTheme,
      updateBrandingThemes
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  theme: selectRequestData(state, actionTypes.GET_THEME_REQUEST) || {},
  updateBrandingThemesJob: selectRequestData(
    state,
    actionTypes.UPDATE_BRANDING_THEMES_REQUEST
  ),
  updateBrandingThemesLoadingState: selectRequestState(
    state,
    actionTypes.UPDATE_BRANDING_THEMES_REQUEST
  )
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Branding);
