import Form, { FormItem } from "../../../components/Form";
import { useString as s } from "../../../components/StringProvider";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Input, { TextArea } from "../../../components/Input";
import NewButton from "../../../components/NewButton";
import { useMobileMediaQuery } from "../../../components/Responsive";
import getStringsFormConfig from "./strings-form-config";
import { Space } from "antd";
import styled from "styled-components";
import ButtonGroup from "../../../components/ButtonGroup";
import Text from "../../../components/Text";
import { GroupCollapse } from "../../../components";
import Select from "../../../components/Select";
import { Prompt } from "react-router-dom";

const StringGroups = {
  GENERAL_STRING: "generalString",
  DISCOVERY_TYPE_STRING: "discoveryTypeString"
};

const defaultDiscoveryTypeCode = "";

const UpdateBrandingStringsForm = ({
  form,
  initialValues,
  onSubmitStrings,
  submitButtonText,
  discoveryTypes
}) => {
  const generalStringsTitle = s(
    "organization.page.updateBranding.strings.generalStringsTitle",
    "Strings and Variables Applied to all Discovery Types"
  );
  const discoveryTypeStringsTitle = s(
    "organization.page.updateBranding.strings.discoveryTypeTitle",
    "Strings and Variables By Discovery Type"
  );
  const discoveryTypeInputLabel = s(
    "organization.page.updateBranding.strings.discoveryTypeInputLabel",
    "Discovery Type"
  );
  const unsavedChangesMessage = s(
    "organization.page.updateBranding.strings.unsavedChangesMessage",
    "You have unsaved changes. Are you sure you wish to proceed and leave this page?"
  );
  const discoveryTypeDefaultName = s(
    "organization.page.updateBranding.strings.discoveryTypeDefaultName",
    "Default"
  );

  const isMobile = useMobileMediaQuery();
  const maxWidth = "673px";

  const discoveryTypeDefault = {
    order: -1,
    code: defaultDiscoveryTypeCode,
    name: discoveryTypeDefaultName
  };

  const [isAnyFormFieldEdited, setIsAnyFormFieldEdited] = useState(false);
  const [selectedDiscoveryTypeCode, setSelectedDiscoveryTypeCode] = useState(
    defaultDiscoveryTypeCode
  );

  const stringsFormConfigGroups = getStringsFormConfig({
    s,
    discoveryTypeCode: selectedDiscoveryTypeCode
  });

  const initialGroupCollapsedState = {
    ...stringsFormConfigGroups.generalStrings.reduce((map, group) => {
      map[`${StringGroups.GENERAL_STRING}-${group.groupId}`] = true;
      return map;
    }, {}),
    ...stringsFormConfigGroups.discoveryTypeStrings.reduce((map, group) => {
      map[`${StringGroups.DISCOVERY_TYPE_STRING}-${group.groupId}`] = true;
      return map;
    }, {})
  };

  const [groupCollapsedState, setGroupCollapsedState] = useState(
    initialGroupCollapsedState
  );

  const discoveryTypesOptions =
    [discoveryTypeDefault]
      .concat(discoveryTypes || [])
      ?.map((discoveryType) => {
        return {
          key: discoveryType?.order,
          value: discoveryType?.code,
          label: discoveryType?.name
        };
      }) || [];

  const onValuesChange = () => {
    const touchedFields = form.isFieldsTouched();
    setIsAnyFormFieldEdited(touchedFields);
  };

  const onToggleCollapsed = (groupId) => {
    setGroupCollapsedState((prevState) => ({
      ...prevState,
      [groupId]: !prevState[groupId]
    }));
  };

  const onFinish = (values) => {
    setIsAnyFormFieldEdited(false);
    onSubmitStrings(values);
  };

  const label = ({ value }) => (
    <Label>
      <Text variant={"bMedium"} color={"gray4"}>
        {value.label}
      </Text>
      <Text variant={"small"} color={"gray4"}>
        {value.name}
      </Text>
    </Label>
  );

  const renderGroups = (groups, prefix) =>
    groups.map((group) => (
      <GroupContainer
        isMobile={isMobile}
        inputWidth={maxWidth}
        key={`${prefix}-${group.groupId}`}
        data-cy={`${prefix}-${group.groupId}`}
      >
        <GroupCollapse
          title={group.groupName}
          onCollapse={() => onToggleCollapsed(`${prefix}-${group.groupId}`)}
          collapsed={groupCollapsedState[`${prefix}-${group.groupId}`]}
        >
          <Space direction={"vertical"}>
            {group.values.map((value, index) => (
              <FormItem
                key={index}
                name={value.name}
                label={label({ value })}
                style={{ maxWidth }}
              >
                {value.name.match(/\.body$/) ? <TextArea /> : <Input />}
              </FormItem>
            ))}
          </Space>
        </GroupCollapse>
      </GroupContainer>
    ));

  return (
    <Form
      form={form}
      initialValues={initialValues}
      layout="vertical"
      onFinish={onFinish}
      name={"updateBrandingStringsForm"}
      onValuesChange={onValuesChange}
    >
      <GroupTitleContainer>
        <Text variant={"h5"} color={"gray4"}>
          {generalStringsTitle}
        </Text>
      </GroupTitleContainer>
      {renderGroups(
        stringsFormConfigGroups.generalStrings,
        StringGroups.GENERAL_STRING
      )}
      {discoveryTypesOptions?.length > 0 && (
        <>
          <GroupTitleContainer>
            <Text variant={"h5"} color={"gray4"}>
              {discoveryTypeStringsTitle}
            </Text>
          </GroupTitleContainer>
          <FormItem label={discoveryTypeInputLabel}>
            <Select
              options={discoveryTypesOptions}
              defaultValue={discoveryTypesOptions[0]}
              disabled={discoveryTypesOptions?.length === 1}
              onChange={(value) => setSelectedDiscoveryTypeCode(value)}
            />
          </FormItem>
          {renderGroups(
            stringsFormConfigGroups.discoveryTypeStrings,
            StringGroups.DISCOVERY_TYPE_STRING
          )}
        </>
      )}
      <FormItem>
        <ButtonGroup mobile={isMobile}>
          <NewButton
            type={"submit"}
            data-cy={"update-branding-strings-form-save"}
            disabled={!isAnyFormFieldEdited}
          >
            {submitButtonText}
          </NewButton>
        </ButtonGroup>
      </FormItem>
      <Prompt when={isAnyFormFieldEdited} message={unsavedChangesMessage} />
    </Form>
  );
};

const Label = styled.div`
  display: flex;
  flex-direction: column;
`;

const GroupContainer = styled.div`
  margin-bottom: 30px;
  width: ${({ isMobile, isTablet, inputWidth }) =>
    isTablet ? "420px" : isMobile ? undefined : inputWidth};
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & .ant-form-item-label {
    max-height: unset;
  }

  & textarea {
    min-height: 300px;
  }
`;

const GroupTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 32px;

  span:first-of-type {
    text-transform: uppercase;
  }
`;

UpdateBrandingStringsForm.propTypes = {
  form: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSubmitStrings: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired
};

export default UpdateBrandingStringsForm;
