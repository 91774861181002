import { Phases } from "../utils/phases";
import { useString as s } from "./StringProvider";

const PhaseName = ({ phase }) => {
  const questions = s("phases.questions", "{segmentation}");
  const challengesSolved = s("phases.challengesSolved", "{challenges}");
  const kpisImpacted = s("phases.kpisImpacted", "{outcomes}");
  const metrics = s("phases.metrics", "{inputs}");
  const valueHypothesis = s("phases.valueHypothesis", "{valueHypothesis}");
  const returnOnInvestment = s("phases.returnOnInvestment", "{roi}");

  switch (phase) {
    case Phases.QUESTIONS:
      return questions;
    case Phases.CHALLENGES_SOLVED:
      return challengesSolved;
    case Phases.KPIS_IMPACTED:
      return kpisImpacted;
    case Phases.METRICS:
      return metrics;
    case Phases.VALUE_HYPOTHESIS:
      return valueHypothesis;
    case Phases.ROI:
      return returnOnInvestment;
  }
};

export default PhaseName;
