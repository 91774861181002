import { useSetting } from "../../SettingsProvider";
import Form, { FormItem } from "../../Form";
import { useString as s } from "../../StringProvider";
import Input from "../../Input";
import Select, { Option } from "../../Select";
import styled from "styled-components";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { selectAllDiscoveryTypes } from "../../../store/reducers";
import NewButton from "../../NewButton";
import { useMobileMediaQuery } from "../../Responsive";
import ButtonGroup from "../../ButtonGroup";
import { useFeature } from "../../FeaturesProvider";
import SelectAccount from "./SelectAccount";
import SelectOpportunity from "./SelectOpportunity";
import SourceTypes from "../../../utils/source-types";

const DiscoveryForm = ({
  form,
  okText,
  onFinish,
  accountSource,
  opportunitySource,
  isDiscoveryTypeEditable,
  isOpportunityEditable,
  isNameEditable,
  isVersionEditable,
  isCurrencyEditable,
  discoveryTypes,
  loading,
  children
}) => {
  const isMobile = useMobileMediaQuery();
  const crmAccountsIntegration = useFeature("crmAccountsIntegration", false);
  const salesforceIntegration = useFeature("salesforceIntegration", false);
  const discoveryTypeLabelText = s(
    "new_discovery.popup.discovery_type.header",
    "Discovery Type"
  );
  const discoveryTypePlaceholderText = s(
    "new_discovery.popup.discovery_type.placeholder",
    "Who are you selling for?"
  );
  const discoveryTypeValidation = s(
    "new_discovery.popup.discovery_type.validation",
    "Please select Discovery Type!"
  );
  const nameLabel = s("new_discovery.popup.box1.header", "{accountFieldName}");
  const nameValidation = s(
    "new_discovery.popup.name.validation",
    "Please input Name!"
  );
  const namePlaceholder = s(
    "new_discovery.popup.box1.text",
    "Type an {accountFieldName}"
  );
  const opportunityLabel = s(
    "new_discovery.popup.box2.header",
    "{opportunityFieldName}"
  );
  const opportunityPlaceholder = s(
    "new_discovery.popup.box2.text",
    "Enter the {opportunityFieldName} description"
  );
  const opportunityValidation = s(
    "new_discovery.popup.opportunity.validation",
    "Please input {opportunityFieldName}!"
  );
  const versionValidation = s(
    "new_discovery.popup.version.validation",
    "Please input Description!"
  );
  const currencyValidation = s(
    "new_discovery.popup.currency.validation",
    "Required"
  );
  const currencies = useSetting("currencies", []);
  const options = (currencies.length ? currencies : ["GBP", "EUR", "USD"]).map(
    (currency) => ({
      label: currency,
      value: currency
    })
  );

  const onUseAccountName = (accountName) => {
    form.setFieldsValue({
      accountId: undefined,
      accountName,
      accountSource: undefined
    });
  };

  const onUseAccountId = (accountId, accountName, accountSource) => {
    form.setFieldsValue({ accountId, accountName, accountSource });
  };

  const onUseOpportunityName = (opportunityName) => {
    form.setFieldsValue({
      opportunityId: undefined,
      opportunityName,
      opportunitySource: undefined
    });
  };

  const onUseOpportunityId = (
    opportunityId,
    opportunityName,
    opportunitySource
  ) => {
    form.setFieldsValue({ opportunityId, opportunityName, opportunitySource });
  };

  const onFinishInternal = (values) => {
    if (values.accountId === undefined) {
      delete values.accountId;
    }

    if (values.opportunityId === undefined) {
      delete values.opportunityId;
    }

    onFinish(values);
  };

  const currencyFormItem = (
    <FormItem
      label={s("new_discovery.popup.box4.header", "Currency")}
      name="currency"
      rules={[
        {
          required: true,
          message: currencyValidation
        }
      ]}
    >
      <Select
        data-cy={"currency-select"}
        disabled={!isCurrencyEditable}
        style={{ minWidth: "80px" }}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            <span>{option.label}</span>
          </Option>
        ))}
      </Select>
    </FormItem>
  );

  const discoveryDescriptionFormItem = (
    <FormItem
      label={s("new_discovery.popup.box3.header", "Discovery Description")}
      name="version"
      rules={[
        {
          required: true,
          message: versionValidation,
          whitespace: true
        }
      ]}
      style={{ flexGrow: 1, paddingRight: isMobile ? 0 : 24 }}
    >
      <Input
        block
        placeholder={s(
          "new_discovery.popup.box3.text",
          "E.g. number, date or name"
        )}
        disabled={!isVersionEditable}
      />
    </FormItem>
  );

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        currency: useSetting("currency", "GBP")
      }}
      onFinish={onFinishInternal}
      name={"search"}
    >
      {crmAccountsIntegration || salesforceIntegration ? (
        <>
          <FormItem hidden name="accountId">
            <Input type="hidden" />
          </FormItem>
          <FormItem hidden name="opportunityId">
            <Input type="hidden" />
          </FormItem>
          <FormItem hidden name="accountSource">
            <Input type="hidden" />
          </FormItem>
          <FormItem hidden name="opportunitySource">
            <Input type="hidden" />
          </FormItem>
        </>
      ) : null}
      <FormItem
        label={nameLabel}
        name="accountName"
        rules={[
          {
            required: true,
            message: nameValidation,
            whitespace: true
          }
        ]}
      >
        {crmAccountsIntegration || salesforceIntegration ? (
          <SelectAccount
            onUseAccountId={onUseAccountId}
            onUseAccountName={onUseAccountName}
            accountSource={accountSource}
            placeholder={namePlaceholder}
            disabled={!isNameEditable}
          />
        ) : (
          <Input
            block
            placeholder={namePlaceholder}
            disabled={!isNameEditable}
          />
        )}
      </FormItem>
      <FormItem
        label={opportunityLabel}
        name="opportunityName"
        rules={[
          {
            required: true,
            message: opportunityValidation,
            whitespace: true
          }
        ]}
      >
        {crmAccountsIntegration || salesforceIntegration ? (
          <SelectOpportunity
            onUseOpportunityId={onUseOpportunityId}
            onUseOpportunityName={onUseOpportunityName}
            opportunitySource={opportunitySource}
            placeholder={opportunityPlaceholder}
            disabled={!isOpportunityEditable}
          />
        ) : (
          <Input
            block
            placeholder={opportunityPlaceholder}
            disabled={!isOpportunityEditable}
          />
        )}
      </FormItem>
      <FormRow>
        {!isMobile && discoveryDescriptionFormItem}
        {!isMobile && currencyFormItem}
      </FormRow>
      {isMobile && discoveryDescriptionFormItem}
      {isMobile && currencyFormItem}
      {discoveryTypes && discoveryTypes.length > 1 ? (
        <FormItem
          label={discoveryTypeLabelText}
          name="discoveryTypeCode"
          rules={[{ required: true, message: discoveryTypeValidation }]}
        >
          <Select
            placeholder={discoveryTypePlaceholderText}
            disabled={isDiscoveryTypeEditable ? undefined : true}
            data-cy={"discovery-type-select"}
          >
            {discoveryTypes.map((option) => (
              <Option key={option.code} value={option.code}>
                <span>{option.name}</span>
              </Option>
            ))}
          </Select>
        </FormItem>
      ) : null}
      <FormItem>
        <ButtonGroup>
          <NewButton
            type={"submit"}
            data-cy={"create-discovery-button"}
            loading={loading}
            disabled={loading}
          >
            {okText}
          </NewButton>
          {children}
        </ButtonGroup>
      </FormItem>
    </Form>
  );
};

DiscoveryForm.propTypes = {
  form: PropTypes.object.isRequired,
  okText: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
  isDiscoveryTypeEditable: PropTypes.bool,
  isOpportunityEditable: PropTypes.bool,
  isNameEditable: PropTypes.bool,
  isCurrencyEditable: PropTypes.bool,
  accountSource: PropTypes.oneOf(SourceTypes.ALL),
  opportunitySource: PropTypes.oneOf(SourceTypes.ALL),
  loading: PropTypes.bool
};

DiscoveryForm.defaultProps = {
  isOpportunityEditable: true,
  isNameEditable: true,
  isVersionEditable: true,
  isCurrencyEditable: true,
  loading: false
};

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const mapStateToProps = (state) => ({
  discoveryTypes: selectAllDiscoveryTypes(state)
});

export default compose(connect(mapStateToProps))(DiscoveryForm);
