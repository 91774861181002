import httpService from "./http.service";

const getDiscovery = ({ discoveryId }) =>
  httpService
    .get(`/discoveries/${discoveryId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getDiscoverySummary = ({ discoveryId }) =>
  httpService
    .get(`/discoveries/${discoveryId}/summary`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const exportDiscoveries = ({ format }) =>
  httpService
    .post(`/discoveries/export`, { format })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getDiscoveryByReportId = ({ reportId }) =>
  httpService
    .get(`/reports/${reportId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const saveDiscoveryQuestion = ({ discoveryId, questionNumber, answer }) =>
  httpService
    .patch(`/discoveries/${discoveryId}/questions/${questionNumber}`, {
      answer: answer
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const saveDiscoveryChallenge = ({ discoveryId, challengeCode, changes }) =>
  httpService
    .patch(`/discoveries/${discoveryId}/challenges/${challengeCode}`, changes)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const addDiscoveryChallengeFromLibrary = ({
  discoveryId,
  challengeCode,
  position
}) =>
  httpService
    .post(
      `/discoveries/${discoveryId}/challenges/${challengeCode}/addFromLibrary`,
      { position }
    )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const removeChallengeFromDiscovery = ({ discoveryId, challengeCode }) =>
  httpService
    .post(
      `/discoveries/${discoveryId}/challenges/${challengeCode}/removeFromDiscovery`
    )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const deleteDiscoveryChallenge = ({ discoveryId, challengeCode }) =>
  httpService
    .delete(`/discoveries/${discoveryId}/challenges/${challengeCode}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const restoreDiscoveryChallenge = ({ discoveryId, challengeCode }) =>
  httpService
    .post(
      `/discoveries/${discoveryId}/challenges/${challengeCode}/restoreToDiscovery`
    )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const saveDiscoveryKPI = ({ discoveryId, kpiCode, changes }) =>
  httpService
    .patch(`/discoveries/${discoveryId}/kpis/${kpiCode}`, changes)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const previewDiscoveryKPI = ({
  discoveryId,
  kpiCode,
  kpiChanges,
  metricChanges
}) =>
  httpService
    .post(`/discoveries/${discoveryId}/kpis/${kpiCode}/preview`, {
      kpiChanges,
      metricChanges
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const commitPreviewDiscoveryKPI = ({
  discoveryId,
  kpiCode,
  kpiChanges,
  metricChanges
}) =>
  httpService
    .post(`/discoveries/${discoveryId}/kpis/${kpiCode}/commitPreview`, {
      kpiChanges,
      metricChanges
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const addDiscoveryKPIFromLibrary = ({ discoveryId, kpiCode, order }) =>
  httpService
    .post(`/discoveries/${discoveryId}/kpis/${kpiCode}/addFromLibrary`, {
      order
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const removeDiscoveryKPIFromLibrary = ({ discoveryId, kpiCode }) =>
  httpService
    .post(`/discoveries/${discoveryId}/kpis/${kpiCode}/removeFromDiscovery`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const freezeDiscoveryKPIs = ({ discoveryId }) =>
  httpService
    .post(`/discoveries/${discoveryId}/kpis/freeze`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const reorderDiscoveryKPIs = ({ discoveryId, codes }) =>
  httpService
    .post(`/discoveries/${discoveryId}/kpis/reorder`, {
      codes
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const reorderDiscoveryChallenges = ({ discoveryId, keyObjectiveCode, codes }) =>
  httpService
    .post(`/discoveries/${discoveryId}/challenges/reorder`, {
      keyObjectiveCode,
      codes
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const deleteDiscoveryKPI = ({ discoveryId, kpiCode }) =>
  httpService
    .delete(`/discoveries/${discoveryId}/kpis/${kpiCode}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const restoreDiscoveryKPI = ({ discoveryId, kpiCode }) =>
  httpService
    .post(`/discoveries/${discoveryId}/kpis/${kpiCode}/restoreToDiscovery`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const saveDiscoveryMetric = ({ discoveryId, metricCode, changes }) =>
  httpService
    .patch(`/discoveries/${discoveryId}/metrics/${metricCode}`, changes)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const resetDiscoveryMetric = ({ discoveryId, metricCode }) =>
  httpService
    .post(`/discoveries/${discoveryId}/metrics/${metricCode}/reset`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const saveDiscoveryROI = ({ discoveryId, changes }) =>
  httpService
    .patch(`/discoveries/${discoveryId}/roi`, changes)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const saveDiscoveryROIOverride = ({
  discoveryId,
  outcomeOverrideType,
  kpiCode,
  value
}) =>
  httpService
    .put(
      `/discoveries/${discoveryId}/roiOverride/${kpiCode}/${outcomeOverrideType}`,
      {
        value
      }
    )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const deleteDiscoveryROIOverride = ({
  discoveryId,
  outcomeOverrideType,
  kpiCode
}) =>
  httpService
    .delete(
      `/discoveries/${discoveryId}/roiOverride/${kpiCode}/${outcomeOverrideType}`
    )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const saveDiscoveryCheckpoint = ({ discoveryId, checkpoint, lastViewedPage }) =>
  httpService
    .patch(`/discoveries/${discoveryId}/checkpoint`, {
      checkpoint,
      lastViewedPage
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const resetDiscovery = ({ discoveryId }) =>
  httpService
    .post(`/discoveries/${discoveryId}/reset`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const saveDiscoveryScenario = ({ discoveryId, scenario }) =>
  httpService
    .post(`/discoveries/${discoveryId}/scenario`, { scenario })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const saveDiscoveryDelayTimeline = ({ discoveryId, delayTimeline }) =>
  httpService
    .post(`/discoveries/${discoveryId}/delayTimeline`, { delayTimeline })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const saveDiscoveryROIBenefits = ({ discoveryId, benefits }) =>
  httpService
    .post(`/discoveries/${discoveryId}/roi/benefits`, benefits)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getAllDiscoveries = () =>
  httpService
    .get(`/discoveries`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getMyDiscoveries = (filters) =>
  httpService
    .get(`/discoveries/me`, null, filters)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const addDiscovery = (discoveryData) =>
  httpService
    .post("/discoveries/", discoveryData)
    .then(({ data, response }) => {
      if (response) {
        switch (response.status) {
          case 409:
            throw new Error("DuplicateDiscovery");
          default:
            return data;
        }
      }
      return data;
    })
    .catch((err) => Promise.reject(err.response));

const cloneDiscovery = ({ discoveryId, discoveryData }) =>
  httpService
    .post(`/discoveries/${discoveryId}/clone`, discoveryData)
    .then(({ data, response }) => {
      if (response) {
        switch (response.status) {
          case 409:
            throw new Error("DuplicateDiscovery");
          default:
            return data;
        }
      }
      return data;
    })
    .catch((err) => Promise.reject(err.response));

const updateDiscovery = ({ discoveryId, data }) =>
  httpService
    .patch(`/discoveries/${discoveryId}`, data)
    .then(({ data, response }) => {
      if (response) {
        switch (response.status) {
          case 409:
            throw new Error("DuplicateDiscovery");
          default:
            return data;
        }
      }
      return data;
    })
    .catch((err) => Promise.reject(err.response));

const updateDiscoveryCanvas = ({
  discoveryId,
  canvasLogoFileId,
  canvasTitle
}) =>
  httpService
    .patch(`/discoveries/${discoveryId}/canvas`, {
      canvasLogoFileId,
      canvasTitle
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getExportTemplates = ({ discoveryId, format }) =>
  httpService
    .get(`/discoveries/${discoveryId}/templates?format=${format}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const exportPowerpoint = ({ discoveryId, template }) =>
  httpService
    .post(`/discoveries/${discoveryId}/export`, {
      template,
      format: "powerpoint"
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const exportDiscovery = ({ reportId, template, format }) =>
  httpService
    .post(`/reports/${reportId}/export`, {
      template,
      format
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const deleteDiscovery = ({ discoveryId }) =>
  httpService
    .delete(`/discoveries/${discoveryId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getCommentCount = ({ discoveryId }) =>
  httpService
    .get(`/discoveries/${discoveryId}/commentCount`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const createDiscoveryKPI = ({ discoveryId, data }) =>
  httpService
    .post(`/discoveries/${discoveryId}/kpis`, data)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const cloneDiscoveryKPI = ({ discoveryId, kpiCode, data }) =>
  httpService
    .post(`/discoveries/${discoveryId}/kpis/${kpiCode}/clone`, data)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const createDiscoveryChallenge = ({ discoveryId, data }) =>
  httpService
    .post(`/discoveries/${discoveryId}/challenges`, data)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const saveDiscoverySolutions = ({ discoveryId, solutionsMap }) =>
  httpService
    .patch(`/discoveries/${discoveryId}/solutions`, solutionsMap)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const previewDiscoverySolutions = ({ discoveryId, solutionsMap }) =>
  httpService
    .post(`/discoveries/${discoveryId}/solutions/preview`, solutionsMap)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const requestROI = ({ discoveryId }) =>
  httpService
    .post(`/discoveries/${discoveryId}/requestROI`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const registerInterest = ({ discoveryId, ...data }) =>
  httpService
    .post(`/discoveries/${discoveryId}/registerInterest`, data)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getEmailContent = ({
  discoveryId,
  subjectTemplate,
  bodyTemplate,
  data
}) =>
  httpService
    .post(`/discoveries/${discoveryId}/email`, {
      subjectTemplate,
      bodyTemplate,
      data
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const calculateKPIFormula = ({ discoveryId, kpiCode, estimationScenario }) =>
  httpService
    .post(`/discoveries/${discoveryId}/kpis/${kpiCode}/calculateDescription`, {
      estimationScenario
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const updateDiscoverySettings = ({ discoveryId, changes }) =>
  httpService
    .patch(`/discoveries/${discoveryId}/settings`, changes)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const updateDiscoverySettingsGroup = ({ discoveryId, groupId, status }) =>
  httpService
    .patch(`/discoveries/${discoveryId}/settings/groups/${groupId}`, { status })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getDiscoveryChallengeLibrary = ({
  discoveryId,
  start,
  count,
  search,
  sort,
  order,
  searchCategories
}) =>
  httpService
    .get(`/discoveries/${discoveryId}/challenges/library`, null, {
      start,
      count,
      search,
      sort,
      order,
      searchCategories
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getDiscoveryKPILibrary = ({
  discoveryId,
  start,
  count,
  search,
  sort,
  order,
  hasFormula,
  searchCategories
}) =>
  httpService
    .get(`/discoveries/${discoveryId}/kpis/library`, null, {
      start,
      count,
      search,
      sort,
      order,
      hasFormula,
      searchCategories
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const createSampleDiscoveries = () =>
  httpService
    .post("/discoveries/createSamples")
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const shareDiscoveryAccess = ({
  discoveryId,
  emails,
  accessType,
  sendEmail,
  message,
  discoveryStage
}) =>
  httpService
    .post(`/discoveries/${discoveryId}/share`, {
      emails,
      accessType,
      sendEmail,
      message,
      discoveryStage
    })
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getDiscoveryNotifications = ({
  discoveryId,
  external,
  start,
  count,
  sort,
  order
}) =>
  httpService
    .get(
      `/discoveries/${discoveryId}/notifications`,
      {},
      {
        external:
          external !== undefined ? encodeURIComponent(external) : undefined,
        start,
        count,
        sort,
        order
      }
    )
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getDiscoveryInvitations = ({ discoveryId }) =>
  httpService
    .get(`/discoveries/${discoveryId}/invitations`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const deleteDiscoveryInvitation = ({ discoveryId, invitationId }) =>
  httpService
    .delete(`/discoveries/${discoveryId}/invitations/${invitationId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const resetDiscoveryAiConversation = ({ discoveryId }) =>
  httpService
    .post(`/discoveries/${discoveryId}/resetAIConversation`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const methods = {
  getDiscovery,
  exportDiscoveries,
  getDiscoveryByReportId,
  saveDiscoveryQuestion,
  saveDiscoveryChallenge,
  deleteDiscoveryChallenge,
  restoreDiscoveryChallenge,
  saveDiscoveryKPI,
  previewDiscoveryKPI,
  commitPreviewDiscoveryKPI,
  addDiscoveryKPIFromLibrary,
  removeDiscoveryKPIFromLibrary,
  deleteDiscoveryKPI,
  restoreDiscoveryKPI,
  saveDiscoveryMetric,
  resetDiscoveryMetric,
  saveDiscoveryROI,
  saveDiscoveryROIOverride,
  deleteDiscoveryROIOverride,
  saveDiscoveryCheckpoint,
  resetDiscovery,
  saveDiscoveryScenario,
  saveDiscoveryDelayTimeline,
  saveDiscoveryROIBenefits,
  getAllDiscoveries,
  getMyDiscoveries,
  addDiscovery,
  cloneDiscovery,
  updateDiscovery,
  updateDiscoveryCanvas,
  getExportTemplates,
  exportPowerpoint,
  exportDiscovery,
  deleteDiscovery,
  getCommentCount,
  createDiscoveryKPI,
  cloneDiscoveryKPI,
  createDiscoveryChallenge,
  saveDiscoverySolutions,
  previewDiscoverySolutions,
  requestROI,
  registerInterest,
  getEmailContent,
  calculateKPIFormula,
  getDiscoverySummary,
  updateDiscoverySettings,
  updateDiscoverySettingsGroup,
  getDiscoveryChallengeLibrary,
  addDiscoveryChallengeFromLibrary,
  removeChallengeFromDiscovery,
  getDiscoveryKPILibrary,
  createSampleDiscoveries,
  freezeDiscoveryKPIs,
  reorderDiscoveryKPIs,
  reorderDiscoveryChallenges,
  shareDiscoveryAccess,
  getDiscoveryNotifications,
  getDiscoveryInvitations,
  deleteDiscoveryInvitation,
  resetDiscoveryAiConversation
};
export default methods;
