import Form, { FormItem } from "../../Form";
import { useString as s } from "../../StringProvider";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Space } from "antd";
import { themeProp } from "../../../theme";
import CategoryBadges from "../CategoryBadges";
import KPINameTextArea from "./KPINameTextArea";
import BenefitTextArea from "./BenefitTextArea";
import PropType from "prop-types";
import NewButton from "../../NewButton";
import KPIData from "../KPICard/KPIData";
import ButtonGroup from "../../ButtonGroup";
import { Desktop, MobileOrTablet } from "../../Responsive";
import AboutThisKPI from "./AboutThisKPI";

const ExistingKPIForm = ({
  form,
  currency,
  discoveryKPI,
  error,
  onFinish,
  onCancel
}) => {
  const okText = s("editKPI.popup.save", "Save card");
  const cancelText = s("editKPI.popup.cancel", "Don't change");
  const nameValidationText = s(
    "editKPI.popup.name.validationText",
    "Please add an outcome name to save"
  );
  const duplicateErrorText = s(
    "editKPI.popup.name.duplicateError",
    "Outcome name already exists"
  );
  const benefitValidationText = s(
    "editKPI.popup.benefitValueDriverDescription.validationText",
    "Please add an outcome benefit description to save"
  );
  const textRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (textRef.current) {
        textRef.current.focus({
          cursor: "end",
          preventScroll: true
        });
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [textRef]);

  useEffect(() => {
    if (error && error.status === 409) {
      form.setFields([
        {
          name: "name",
          errors: [duplicateErrorText]
        }
      ]);
      setTimeout(() => {
        textRef.current.focus({
          cursor: "end"
        });
      }, 1);
    }
  }, [error, textRef]);

  const kpiNameFormItem = (
    <FormItem
      data-cy={"form-item-kpi-name"}
      name="name"
      rules={[
        {
          required: true,
          message: nameValidationText,
          whitespace: true
        }
      ]}
    >
      <KPINameTextArea autoSize={true} ref={textRef} />
    </FormItem>
  );

  const benefitFormItem = (
    <FormItem
      data-cy={"form-item-kpi-benefitValueDriverDescription"}
      name="benefitValueDriverDescription"
      rules={[
        {
          required: true,
          message: benefitValidationText,
          whitespace: true
        }
      ]}
    >
      <BenefitTextArea autoSize={true} />
    </FormItem>
  );

  const buttons = (
    <FormItem>
      <ButtonGroup>
        <NewButton type={"submit"}>{okText}</NewButton>
        <NewButton
          type={"secondary"}
          onClick={onCancel}
          data-cy={"cancel-button"}
        >
          {cancelText}
        </NewButton>
      </ButtonGroup>
    </FormItem>
  );

  return (
    <Contents>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <MobileOrTablet>
          <Space direction={"vertical"} size={22} style={{ width: "100%" }}>
            {kpiNameFormItem}
            {benefitFormItem}
            <CategoryBadges
              definition={discoveryKPI.definition}
              disabled={true}
            />
            <KPIData
              discoveryKPI={discoveryKPI}
              currency={currency}
              disabled={true}
              isEditable={false}
            />
            <AboutThisKPI
              discoveryKPI={discoveryKPI}
              defaultExpandedState={true}
              editMode={true}
            />
            {buttons}
          </Space>
        </MobileOrTablet>
        <Desktop>
          <Columns>
            <LeftColumn>
              <Space direction={"vertical"} size={8} style={{ width: "100%" }}>
                {kpiNameFormItem}
                {benefitFormItem}
                <CategoryBadges
                  definition={discoveryKPI.definition}
                  disabled={true}
                />
              </Space>
            </LeftColumn>
            <RightColumn>
              <KPIData
                discoveryKPI={discoveryKPI}
                currency={currency}
                disabled={true}
                isEditable={false}
              />
            </RightColumn>
          </Columns>
          <AboutThisKPI
            discoveryKPI={discoveryKPI}
            defaultExpandedState={true}
            style={{ marginTop: 9, marginBottom: 16 }}
            editMode={true}
          />
          {buttons}
        </Desktop>
      </Form>
    </Contents>
  );
};

const Contents = styled.div`
  display: flex;
  flex-direction: column;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftColumn = styled.div`
  flex-grow: 1;
  padding-right: 30px;
`;

const RightColumn = styled.div`
  margin-top: 8px;
  padding-top: ${themeProp("typography.h3.lineHeight")};
  width: 250px;
  min-width: 250px;
`;

ExistingKPIForm.propTypes = {
  discoveryKPI: PropType.object.isRequired,
  currency: PropType.string.isRequired,
  form: PropType.object.isRequired,
  onFinish: PropType.func.isRequired,
  onCancel: PropType.func.isRequired,
  error: PropType.object
};

export default ExistingKPIForm;
