import React from "react";
import Heading from "../../../../components/Heading";
import Icon from "../../../../components/Icon";
import { Text, Tooltip } from "../../../../components";
import RelatedChallengeDescription from "../../../../components/Discovery/KPICard/RelatedChallengeDescription";
import styled, { css } from "styled-components";
import { themeProp } from "../../../../theme";
import { useEffect, useState } from "react";
import PropType from "prop-types";
import DiscoveryKPIPercentage from "./DiscoveryKPIPercentage";
import DiscoveryKPIImpactValue from "./DiscoveryKPIImpactValue";
import { useString as s } from "../../../../components/StringProvider";

const StandardDetails = ({
  discoveryKPI,
  reportId,
  currency,
  showAllChallenges,
  openEditModal,
  printMode
}) => {
  const [isOpen, setOpen] = useState(false);
  const challengesText = s(
    "discovery.opportunity.page.challenges.label",
    "{challenges}"
  );

  useEffect(() => {
    setOpen(showAllChallenges);
  }, [showAllChallenges]);

  const onToggleOpen = () => {
    setOpen(!isOpen);
  };

  return (
    <>
      <Header>
        <Left>
          <Heading level={"h4"}>{discoveryKPI.definition.name}</Heading>
          {discoveryKPI.definition.benefitValueDriverDescription}
        </Left>
        <Right>
          <Tooltip
            title={discoveryKPI?.definition?.impactPercentageFormulaDescription}
          >
            <RightInner
              onClick={openEditModal}
              className={"outcomes-value-section"}
              disableHover={!reportId}
            >
              <DiscoveryKPIPercentage
                discoveryKPI={discoveryKPI}
                reportId={reportId}
              />

              <DescriptionMetrics>
                <DiscoveryKPIImpactValue
                  discoveryKPI={discoveryKPI}
                  currency={currency}
                  reportId={reportId}
                />
              </DescriptionMetrics>
            </RightInner>
          </Tooltip>
        </Right>
      </Header>
      {discoveryKPI.relatedChallengeCodes.length ? (
        <>
          <ChallengesApproved onClick={onToggleOpen} printMode={printMode}>
            <Heading level={"h5"} color={"gray"}>
              {challengesText}
            </Heading>
            <Icon
              name={isOpen ? "angleUp" : "angleDown"}
              colour={"gray3"}
              size={"sMedium"}
            />
          </ChallengesApproved>
          {isOpen && (
            <DescriptionList>
              {discoveryKPI.relatedChallengeCodes.map(
                (challengeCode, index) => (
                  <DescriptionItem key={index}>
                    <Icon name="check" colour="secondary" />
                    <Text variant={"small"}>
                      <RelatedChallengeDescription
                        challengeCode={challengeCode}
                      />
                    </Text>
                  </DescriptionItem>
                )
              )}
            </DescriptionList>
          )}
        </>
      ) : null}
    </>
  );
};

const Left = styled.div`
  flex-grow: 1;
  width: 100%;
  padding-top: 8px;
`;

const Right = styled.div`
  max-width: 176px;
  min-width: 176px;
  margin-left: 16px;
  text-align: right;
  display: flex;
  justify-content: flex-end;

  h3,
  h4 {
    text-align: right;
    margin-bottom: 0;
  }
`;

const RightInner = styled.div`
  cursor: ${(props) => (props.disableHover ? "pointer" : "auto")};
  border-radius: 4px;
  padding: 8px;

  ${({ disableHover }) =>
    disableHover &&
    css`
      &:hover {
        background-color: ${themeProp("palette.accent1")};

        h4,
        span {
          color: ${themeProp("palette.secondary")};
        }
      }
    `}
`;

const ChallengesApproved = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  cursor: pointer;
  user-select: none;
  line-height: 0;

  h5 {
    height: 100%;
    display: flex;
    align-items: center;
    transform: translateY(3px);
  }

  svg {
    margin-left: 8px;
    vertical-align: super;
    height: 100%;
  }

  ${({ printMode }) =>
    printMode &&
    css`
      display: none;
    `}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  align-items: flex-start;
`;

const DescriptionMetrics = styled.div`
  color: ${themeProp("palette.secondary")};
  text-align: right;
`;

const DescriptionItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;

  svg {
    display: block;
    margin-right: 10px;
    color: ${themeProp("palette.success")};
  }
`;

const DescriptionList = styled.div`
  display: flex;
  flex-direction: column;
`;

StandardDetails.propTypes = {
  discoveryKPI: PropType.object.isRequired,
  currency: PropType.string.isRequired,
  reportId: PropType.string,
  showAllChallenges: PropType.bool,
  openEditModal: PropType.func.isRequired,
  printMode: PropType.bool
};

export default StandardDetails;
