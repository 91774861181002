import {
  selectDiscoveryCurrency,
  selectDiscoveryId,
  selectDiscoveryKPI,
  selectRequestError,
  selectRequestState
} from "../../../store/reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { stopEditingKPI } from "../../../store/actions/edit-kpis";
import { useEffect } from "react";
import { useString as s } from "../../StringProvider";
import ExistingKPIForm from "./ExistingKPIForm";
import Form from "../../Form";
import { saveDiscoveryKPI } from "../../../store/actions/discoveries";
import NewKPIForm from "./NewKPIForm";
import { deleteNullOrUndefinedField } from "../../../utils/formatting";
import DefinitionTypes from "../../../utils/definition-types";
import PropType from "prop-types";
import KPIModal from "./KPIModal";
import actionTypes from "../../../store/actionTypes";
import useLoadingState from "../../../utils/use-loading-state";
import UNCATEGORIZED_CATEGORY_CODE from "../../../utils/uncategorized-category-code";
import { setScrollToKPICode } from "../../../store/actions/kpi-library";
import Notification from "../../Notification";

const EditKPIModal = ({
  discoveryId,
  kpiCode,
  loadingState,
  currency,
  discoveryKPI,
  error,
  saveDiscoveryKPI,
  stopEditingKPI,
  setScrollToKPICode
}) => {
  const errorText = s("editKPI.popup.error.text", "Failed to save outcome");
  const successText = s(
    "editKPI.popup.success.text",
    "Card saved! Changes will be applied to the current discovery only."
  );
  const [form] = Form.useForm();

  useEffect(() => {
    setScrollToKPICode({ kpiCode });
  }, [kpiCode]);

  useLoadingState(
    loadingState,
    () => {
      if (kpiCode) {
        Notification.success(successText);
        stopEditingKPI();
      }
    },
    () => {
      if (!error || error.status !== 409) {
        Notification.error(errorText);
      }
    }
  );

  useEffect(() => {
    if (discoveryKPI) {
      const categoryCode = discoveryKPI.definition.categoryCode;
      const data = {
        name: discoveryKPI.definition.name,
        benefitValueDriverDescription:
          discoveryKPI.definition.benefitValueDriverDescription,
        categoryCode:
          categoryCode !== UNCATEGORIZED_CATEGORY_CODE
            ? categoryCode
            : undefined,
        lowImpactPercentage: discoveryKPI.lowImpactPercentage,
        highImpactPercentage: discoveryKPI.highImpactPercentage,
        challengeMappings: discoveryKPI.relatedChallengeCodes,
        detailText: discoveryKPI.definition.detailText
      };

      deleteNullOrUndefinedField(data, "lowImpactPercentage");
      deleteNullOrUndefinedField(data, "highImpactPercentage");

      form.resetFields();
      form.setFieldsValue(data);
    }
  }, [discoveryKPI]);

  const onFinish = ({
    name,
    benefitValueDriverDescription,
    categoryCode,
    lowImpactPercentage,
    highImpactPercentage,
    challengeMappings,
    detailText
  }) => {
    const changes = {
      lowImpactPercentage,
      highImpactPercentage,
      definition: {
        name,
        benefitValueDriverDescription,
        challengeMappings,
        detailText
      }
    };

    if (discoveryKPI.definition.status === DefinitionTypes.NEW) {
      changes.definition.categoryCode = !categoryCode ? null : categoryCode;
    }

    if (changes.lowImpactPercentage === undefined) {
      changes.lowImpactPercentage = null;
    }

    if (changes.highImpactPercentage === undefined) {
      changes.highImpactPercentage = null;
    }

    if (discoveryKPI.definition.status !== DefinitionTypes.NEW) {
      delete changes.lowImpactPercentage;
      delete changes.highImpactPercentage;
    }

    if (
      !changes.definition.challengeMappings &&
      discoveryKPI.definition.status === DefinitionTypes.NEW
    ) {
      // Make sure to send empty list, to clear out deselected options
      changes.definition.challengeMappings = [];
    }

    saveDiscoveryKPI({
      discoveryId,
      kpiCode,
      changes
    });
  };

  return (
    <KPIModal open={true} data-cy={"edit-kpi-modal"}>
      {discoveryKPI.definition.status === DefinitionTypes.NEW &&
      !discoveryKPI.definition?.clonedKPICode ? (
        <NewKPIForm
          form={form}
          onFinish={onFinish}
          onCancel={stopEditingKPI}
          currency={currency}
          isEdit={true}
          error={error}
        />
      ) : (
        <ExistingKPIForm
          form={form}
          onFinish={onFinish}
          onCancel={stopEditingKPI}
          discoveryKPI={discoveryKPI}
          currency={currency}
          error={error}
        />
      )}
    </KPIModal>
  );
};

EditKPIModal.propTypes = {
  kpiCode: PropType.string.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    discoveryId: selectDiscoveryId(state),
    loadingState: selectRequestState(
      state,
      actionTypes.DISCOVERY_SAVE_KPI_REQUEST
    ),
    error: selectRequestError(state, actionTypes.DISCOVERY_SAVE_KPI_REQUEST),
    currency: selectDiscoveryCurrency(state),
    discoveryKPI: selectDiscoveryKPI(state, props.kpiCode)
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryKPI,
      stopEditingKPI,
      setScrollToKPICode
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EditKPIModal
);
