import Menu, { MenuItem } from "../../components/Menu";
import React from "react";
import { useString as s } from "../../components/StringProvider";
import PropTypes from "prop-types";
import Icon from "../../components/Icon";
import Dropdown from "../../components/Dropdown";
import { Operations } from "../../components/DiscoveryOperations/DiscoveryOperations";
import { bindActionCreators, compose } from "redux";
import { startDiscoveryOperation } from "../../store/actions/discovery-operations";
import { connect } from "react-redux";
import NewButton from "../../components/NewButton";
import { hasPermission } from "../../store/reducers";
import Permissions from "../../utils/permissions";
import { exportDiscovery } from "../../store/actions/discoveries";

const MyDiscoveriesDropdown = ({
  discoveryId,
  reportId,
  startDiscoveryOperation,
  canExportDiscoveryExcel,
  canExportDiscoveryPowerpoint,
  canCloneDiscovery,
  exportDiscovery,
  isMandatoryQuestionsCompleted,
  canExportToPDF
}) => {
  const cloneText = s("discovery.operation.clone.name", "Clone");
  const editText = s("discovery.operation.edit.name", "Edit");
  const exportPPTText = s("discovery.operation.exportPPT.name", "Export PPT");
  const exportExcelText = s(
    "discovery.operation.exportExcel.name",
    "Export data"
  );
  const printText = s("discovery.operation.print.name", "Export PDF report");
  const deleteText = s("discovery.operation.delete.name", "Delete");
  const assignToText = s("discovery.operation.assign.name", "Assign to");
  const moreActionsText = s(
    "discovery.operation.moreActions.name",
    "More actions"
  );

  const onClick = (e) => {
    if (e.key === Operations.EXPORT_DISCOVERY_EXCEL) {
      exportDiscovery({ reportId, format: "data-xlsx" });
    } else {
      startDiscoveryOperation({
        operationId: e.key,
        operationDiscoveryId: discoveryId
      });
    }
  };

  const renderMenu = () => (
    <Menu onClick={onClick}>
      {canCloneDiscovery ? (
        <MenuItem key={Operations.CLONE} icon={<Icon name={"copy"} />}>
          {cloneText}
        </MenuItem>
      ) : null}
      <MenuItem key={Operations.EDIT} icon={<Icon name={"edit"} />}>
        {editText}
      </MenuItem>
      <MenuItem key={Operations.ASSIGN} icon={<Icon name={"assign"} />}>
        {assignToText}
      </MenuItem>
      <Menu.Divider />
      {canExportDiscoveryPowerpoint ? (
        <MenuItem
          key={Operations.EXPORT}
          icon={<Icon name={"powerpoint"} />}
          disabled={!isMandatoryQuestionsCompleted}
        >
          {exportPPTText}
        </MenuItem>
      ) : null}
      {canExportDiscoveryExcel ? (
        <MenuItem
          key={Operations.EXPORT_DISCOVERY_EXCEL}
          icon={<Icon name={"xls"} />}
          disabled={!isMandatoryQuestionsCompleted}
          data-cy={"export-data-menu-item"}
        >
          {exportExcelText}
        </MenuItem>
      ) : null}
      {canExportToPDF ? (
        <MenuItem
          key={Operations.PRINT}
          icon={<Icon name={"pdf"} />}
          disabled={!isMandatoryQuestionsCompleted}
        >
          {printText}
        </MenuItem>
      ) : null}
      <Menu.Divider />
      <MenuItem key={Operations.DELETE} icon={<Icon name={"delete"} />}>
        {deleteText}
      </MenuItem>
    </Menu>
  );

  return (
    <Dropdown
      overlay={renderMenu()}
      placement={"bottomLeft"}
      trigger={["click"]}
    >
      <NewButton
        type={"iconPrimary"}
        className="ant-dropdown-link"
        tooltip={moreActionsText}
        onClick={(e) => e.preventDefault()}
      >
        <Icon name={"dropdown"} size={"medium"} />
      </NewButton>
    </Dropdown>
  );
};

MyDiscoveriesDropdown.propTypes = {
  discoveryId: PropTypes.string.isRequired,
  reportId: PropTypes.string.isRequired,
  isMandatoryQuestionsCompleted: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  canExportDiscoveryExcel: hasPermission(
    state,
    Permissions.EXPORT_DISCOVERY_EXCEL
  ),
  canExportDiscoveryPowerpoint: hasPermission(
    state,
    Permissions.EXPORT_POWERPOINT
  ),
  canCloneDiscovery: hasPermission(
    state,
    Permissions.SEE_CLONE_DISCOVERY_BUTTON
  ),
  canExportToPDF: hasPermission(state, Permissions.EXPORT_TO_PDF)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startDiscoveryOperation,
      exportDiscovery
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MyDiscoveriesDropdown
);
