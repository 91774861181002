const defaultStrings = {
  brand: "default",
  strings: {
    variables: {
      companyName: "companyName Variable Test",
      accountFieldName: "Account Name",
      opportunityFieldName: "Opportunity",
      challenges: "Challenges",
      challenge: "Challenge",
      outcomes: "Outcomes",
      outcome: "Outcome",
      inputs: "Inputs",
      input: "Input",
      segmentation: "Segmentation",
      valueHypothesis: "Value Hypothesis",
      returnOnInvestment: "Return on Investment",
      roi: "ROI"
    }
  }
};

export default defaultStrings;
